import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/RegistrationSteps.css';
import BeatLoader from 'react-spinners/BeatLoader';
import '../css/Login.css';
import Logo from '../assets/images/logo.png';

import {
  Box,
 
} from '@mui/material';
function LevelTestIntro() {
  const navigate = useNavigate();
  const targetLevel = localStorage.getItem('selectedLevel');
  const [loading, setLoading] = useState(false);

  const handleStartTest = async () => {
    setLoading(true);
    try {
      console.log("TARGETLEVEL",targetLevel);
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(`https://api.getmina.co/api/v1/panel/diagnostic-exams/${targetLevel}`, {
        headers: { 'Authorization': `Bearer ${jwt}` }
      });
      const diagnosticExams = await response.json();
      
      if (Array.isArray(diagnosticExams) && diagnosticExams.length > 0) {
        const exam = diagnosticExams[0];
        if (exam.exam_id) {
          navigate(`/diagnostic-exam/${exam.exam_id}`);
        } else {
          console.error("Exam ID not found in the response");
          // Handle the error appropriately
        }
      } else {
        console.error("No diagnostic exams found or unexpected response format");
        // Handle the error appropriately
      }
    } catch (error) {
      console.error("Error fetching diagnostic exam:", error);
      // Handle the error appropriately
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
    <Box className="logo-container-box" sx={{ position: 'relative', top: '0px',  }}>
    <img src={Logo} alt="Mina Logo" style={{ height: '50px' }} />
  </Box>
    <div className='check-body'>
    <div className="registration-step">
      <div className='small-top'>Level Test</div>
      <div className="first-title">Let's check your JLPT level!</div>
      <p className='sub-title'>Based on vast learning data, we provide JLPT levels and AI-customized studies.</p>
      <div className='space'></div>
      <div className='space'></div>

      <button 
        className="start-test-button"
        onClick={handleStartTest}
        disabled={loading}
      >
        {loading ? <BeatLoader color="#ffffff" size={10} /> : 'Start the level test'}
      </button>
    </div>
    </div>
    </div>
  );
}

export default LevelTestIntro;