import React from 'react';

const CategoryFilter = ({ categories, selectedCategories, onToggleCategory }) => (
  <div className="category-boxes-container">
    <div className="category-boxes">
      {categories.map(category => (
        <button
          key={category}
          className={`category-box ${selectedCategories.includes(category) ? 'selected' : ''}`}
          onClick={() => onToggleCategory(category)}
        >
          {category}
        </button>
      ))}
    </div>
  </div>
);

export default CategoryFilter;