// src/Login.js
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import BeatLoader from 'react-spinners/BeatLoader';
import Alert from '@mui/material/Alert';
import Mina from './assets/images/logo.png';
import './css/Login.css';
import mixpanel from './mixpanel';  // Import the initialized Mixpanel instance

// Add these styled components
const StyledTextField = styled(TextField)({
  width: '100%',
  marginBottom: '20px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    '& fieldset': {
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderColor: '#bdbdbd',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#757575',
    },
  },
  '& .MuiInputBase-input': {
    padding: '14px',
  },
});

const FieldTitle = styled(Typography)({
  textAlign: 'left',
  marginBottom: '5px',
  fontWeight: 'bold',
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
  fontWeight: 'bold',
  color: '#5539FF',
  '&:hover': {
    color: '#272499',
    textDecoration: 'none',
  },
});

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    try {
      const response = await fetch('https://api.getmina.co/api/v1/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      const responseData = await response.json();
      setLoading(false);
      if (!response.ok) {
        throw new Error(responseData.message || `Failed to log in: ${response.status} ${response.statusText}`);
      }

      const accessToken = responseData.user?.access;
      const userId = responseData.user?.user_id;  // Assuming user ID is returned in the response
      const userStatus = responseData.status;
      if (accessToken) {
        localStorage.setItem('jwt', accessToken);

        // Mixpanel identify and set user properties
        if (userId) {
          mixpanel.identify(userId);
          // mixpanel.people.set({
          //   $name: `${responseData.FirstName} ${responseData.LastName}`, // Assuming these fields are returned
          //   $email: email,  // Mixpanel's special property for email
          //   status: userStatus,
          //   // Add any other user properties here
          // });

          // Track the login event
          mixpanel.track('Login', {
            email: email,
            status: userStatus
          });
        }

        window.location.href = '/';
      } else {
        throw new Error('Access token is missing in the response');
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message);
    }
  };

  if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;

  return (
    <>
      <Box className="logo-container-box">
        <img src={Mina} alt="Mina Logo" className="mina-logo" />
      </Box>
      <Container component="main" maxWidth="xs" className="login-container">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" className="login-title custom-login-title">
            Log in
          </Typography>
          {errorMessage && (
            <Alert severity="error" sx={{ width: '100%', marginTop: 2 }}>
              {"Login failed, wrong credentials"}
            </Alert>
          )}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
            <FieldTitle>Email Address</FieldTitle>
            <StyledTextField
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              placeholder="Email Address"
            />
            <FieldTitle>Password</FieldTitle>
            <StyledTextField
              required
              fullWidth
              name="password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              placeholder="Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#3E00EF',
                color: 'white',
                borderRadius: '5px',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#272499',
                },
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                '&.Mui-disabled': {
                  backgroundColor: '#c0c0c0',
                  color: '#fff',
                }
              }}
              disabled={loading}
            >
              {loading ? <BeatLoader color="#fff" size={10} /> : 'Log in'}
            </Button>

            <Grid container className="link-container">
              <Grid item className="link-item">
                <span className="link-text">Don't have an account? </span>
                <StyledLink href="/register" variant="body2" className="link">
                  Register
                </StyledLink>
              </Grid>
              <Grid item className="link-item">
                <span className="link-text">Forgot password? </span>
                <StyledLink href="/forgotpassword" variant="body2" className="link">
                  Reset password
                </StyledLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
