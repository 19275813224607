import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './dashboardstyle.css';

function DiagnosticExams() {
  const [diagnosticExams, setDiagnosticExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDiagnosticExams = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch('https://api.getmina.co/api/v1/panel/teacher/diagnostic-exams', {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${jwt}` }
        });
        const data = await response.json();
        setDiagnosticExams(data);
      } catch (error) {
        setError('Failed to fetch diagnostic exams.');
      }
      setLoading(false);
    };

    fetchDiagnosticExams();
  }, []);

  if (loading) return <div className="loader-container">Loading...</div>;
  if (error) return <div className="error-container">{error}</div>;

  return (
    <div className="container">
      <h1 className="page-title">Diagnostic Exams</h1>
      {diagnosticExams.length > 0 ? (
        <ul className="exam-list">
          {diagnosticExams.map((exam) => (
            <li key={exam.exam_id} className="exam-item">
              <Link to={`/exam/${exam.exam_id}/users`} className="exam-link">
                {/* <span className="exam-id">Exam ID: {exam.exam_id}</span> */}
                <span className="exam-level">Level: {exam.level}</span>
                <span className="exam-date">Created: {new Date(exam.created_at).toLocaleDateString()}</span>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p className="no-exams">No diagnostic exams found.</p>
      )}
    </div>
  );
}

export default DiagnosticExams;