import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import '../css/personalized_exam.css';
// import useMediaQuery from '@mui/material/useMediaQuery';
import cancelIcon from '../assets/icons/cancel.png';

function ReviewExamQuestion() {
  const navigate = useNavigate();
  const location = useLocation();
  const { exam_id } = useParams();
  // const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [groupedExamData, setGroupedExamData] = useState([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  useEffect(() => {
    const examData = location.state?.examData || { questions: [] };
    const groupedData = groupQuestionsByScript(examData.questions);
    setGroupedExamData(groupedData);
  }, [location.state]);

  const groupQuestionsByScript = (questions) => {
    const grouped = [];
    let currentGroup = null;

    questions.forEach((question) => {
      if (!currentGroup || currentGroup.script !== question.script) {
        if (currentGroup) {
          grouped.push(currentGroup);
        }
        currentGroup = { script: question.script, questions: [] };
      }
      currentGroup.questions.push(question);
    });

    if (currentGroup) {
      grouped.push(currentGroup);
    }

    return grouped;
  };

  const totalGroups = groupedExamData.length;
  const currentGroup = groupedExamData[currentGroupIndex] || { questions: [] };
  const remainingGroups = totalGroups - currentGroupIndex - 1;

  const handlePreviousGroup = () => {
    if (currentGroupIndex > 0) {
      setCurrentGroupIndex(currentGroupIndex - 1);
    }
  };

  const handleNextGroup = () => {
    if (currentGroupIndex < totalGroups - 1) {
      setCurrentGroupIndex(currentGroupIndex + 1);
    } else {
      navigate(`/review-exam/${exam_id}`);
    }
  };

  const handleBackButtonClick = () => {
    navigate(`/review-exam/${exam_id}`);
  };

  const formatText = (text) => {
    if (!text || text === '0') return '';

    const textWithLineBreaks = text.replace(/{{NEWLINE}}/g, '\n');

    return textWithLineBreaks.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, index) => (
      <span key={index}>
        {part.trim().length === 0 ? <br /> : part.split('\n').map((line, i) => (
          <React.Fragment key={i}>
            {i > 0 && <br />}
            {line}
          </React.Fragment>
        ))}
      </span>
    ));
  };

  if (groupedExamData.length === 0) {
    return <div>No content available</div>;
  }

  return (
    <div className="Exam">
      <div className={`upper-bar`}>
        <div className="upper-left">
          <div onClick={handleBackButtonClick} aria-label="cancel" className="cancel-button-exam">
            <img src={cancelIcon} alt="Cancel" />
          </div>
          <div className="questions-remaining">
            {remainingGroups} question groups remaining
          </div>
        </div>
      </div>

      <div className="content">
        {currentGroup.script && currentGroup.script !== "0" ? (
          <div className="two-column-layout">
            <div className="script-column">
              <div className="question-number">Question Group {currentGroupIndex + 1}</div>
              {formatText(currentGroup.script)}
            </div>
            <div className="questions-column">
              {currentGroup.questions.map((question, index) => (
                <div key={index} className="question-box">
                  {question.instruction && (
                    <div className="instruction-box">
                      <p className="instruction">{formatText(question.instruction)}</p>
                    </div>
                  )}
                  <div className="question-text">{formatText(question.question_text)}</div>
                  <div className="answer-section">
                    {question.choices.choices.map((choice, choiceIndex) => (
                      <div
                        key={choiceIndex}
                        className={`answer-option 
                          ${question.selected_answer === choice ? 'selected' : ''} 
                          ${(choiceIndex + 1).toString() === question.correct_answer ? 'correct' : (question.selected_answer === choice ? 'incorrect' : '')}`}
                      >
                        {choice}
                      </div>
                    ))}
                  </div>
                  {question.explanation && (
                    <div className="explanation-box">
                      <strong>Explanation:</strong> {formatText(question.explanation)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="personalized-page">
            {currentGroup.questions.map((question, index) => (
              <div key={index}>
                <div className='question-title'>Question {currentGroupIndex + 1}.{index + 1}</div>
                {question.instruction && <p>{formatText(question.instruction)}</p>}
                <hr className="question-divider" />
                <div className="question-answer">
                  <div className="question-text">{formatText(question.question_text)}</div>
                  <div className="answer-section">
                    {question.choices.choices.map((choice, choiceIndex) => (
                      <div
                        key={choiceIndex}
                        className={`answer-option 
                          ${question.selected_answer === choice ? 'selected' : ''} 
                          ${(choiceIndex + 1).toString() === question.correct_answer ? 'correct' : (question.selected_answer === choice ? 'incorrect' : '')}`}
                      >
                        {choice}
                      </div>
                    ))}
                  </div>
                  {question.explanation && (
                    <div className="explanation-box">
                      <strong>Explanation:</strong> {formatText(question.explanation)}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={`lower-bar`}>
        <div className="lower-left"></div>
        <div className="lower-right">
          <button className="previous-button" onClick={handlePreviousGroup}>Previous</button>
          <button className="reveal-answer-button" onClick={handleNextGroup}>
            {currentGroupIndex === totalGroups - 1 ? "Finish" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReviewExamQuestion;
