import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/StudyPlan.css';
// import Dropdown from '../components/Dropdown';
import BeatLoader from 'react-spinners/BeatLoader';
// import Logo from '../assets/images/logo.png';
// import Mina from '../assets/images/mina.png';
import TopBar from '../selected_study/home_components/TopBar';
import MobileMenu from '../selected_study/home_components/MobileMenu';
// import UserDropdown from '../components/UserDropdown';
import mixpanel from '../mixpanel';
import LockedExamModal from '../general_components/LockedExamModal'
import '../css/ExamBox.css';  // Add this line;
// import { FaBars,  } from 'react-icons/fa';
import Modal from '../components/Modal'; // Assuming you have a Modal component
// import selectedImage from '../assets/icons/selected.png';

// const GET_PERSONALIZED_EXAMS_API_URL = "https://api.getmina.co/api/v1/panel/get-personalized-exams-user";
// const DIAGNOSTIC_EXAMS_API_URL = "https://api.getmina.co/api/v1/panel/diagnostic-exams";
// const STUDENT_STAGES_API_URL = "https://api.getmina.co/api/v1/panel/student/stages";
const API_BASE_URL = "https://api.getmina.co/api/v1/panel";

// khabbion bel .env ya dandoun 
// --joe

function StudyPlan() {
    const navigate = useNavigate();
    // const levels = ["N1", "N2", "N3", "N4", "N5"];

    // const [selectedLevel] = useState(() => localStorage.getItem('selectedLevel'));
    // const [sectionData, setSectionData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const [userStatus, setUserStatus] = useState(null);
    const [userData, setUserData] = useState(null);
    const [showLockedExamModal, setShowLockedExamModal] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    // const [diagnosticExam, setDiagnosticExam] = useState(null);
    const [stages, setStages] = useState([]);
    const [currentStageIndex, setCurrentStageIndex] = useState(0);
    const [currentExams, setCurrentExams] = useState([]);
    // const [accessibleStages] = useState([]);
    const [comments, setComments] = useState([]);
    const [showRecommendedStudyModal, setShowRecommendedStudyModal] = useState(false);

    // Add this new state variable
    const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);

    const [showComingSoonModal, setShowComingSoonModal] = useState(false);

    // const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
    // const handleLockedExamClick = () => setShowLockedExamModal(true);
   

    const handleLogout = () => {
        localStorage.removeItem('jwt');
        navigate('/login');
    };

    const isStageAccessible = (index) => {
        if (!stages.length) return false;
        // const stage = stages[index];
        
        // Check if all exams in the current stage are completed
        // const allExamsCompleted = stage.exams.every(exam => exam.completed);

        return (
            index === 0 || // First stage is always accessible
            (index > 0 && stages[index - 1].is_completed) // Previous stage must be completed
        );
    };
    const fetchExamsForStage = useCallback(async (stageId) => {
        setLoading(true);
        setError(null);
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch(`${API_BASE_URL}/student/stage/${stageId}/exams`, {
                headers: { 'Authorization': `Bearer ${jwt}` }
            });
            const data = await response.json();
            console.log("data", data);
            setCurrentExams(data);
        } catch (error) {
            console.error("Error fetching exams:", error);
            setError("Error fetching exams");
        } finally {
            setLoading(false);
        }
    }, []);
    const fetchStages = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch(`${API_BASE_URL}/student/stages`, {
                headers: { 'Authorization': `Bearer ${jwt}` }
            });
            const data = await response.json();
            setStages(data);
            console.log("STAGES",data)
            
            if (data.length > 0) {
                const firstIncompleteIndex = data.findIndex(stage => !stage.is_completed);
                const currentIndex = firstIncompleteIndex === -1 ? data.length - 1 : firstIncompleteIndex;
                setCurrentStageIndex(currentIndex);
                fetchExamsForStage(data[currentIndex].stage_id);
            }
        } catch (error) {
            console.error("Error fetching stages:", error);
            setError("Error fetching stages");
        } finally {
            setLoading(false);
        }
    }, [fetchExamsForStage]);
  
    const fetchUserData = async () => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch("https://api.getmina.co/api/v1/auth/me", {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    "Content-Type": "application/json"
                },
            });

            const meData = await response.json();

            if (meData.msg === "Token has expired") {
                localStorage.removeItem('jwt');
                window.location.reload();
            } else {
                setUserStatus(meData.status);
                setUserData(meData);
                mixpanel.identify(meData.user_id);
                setIsUserDataLoaded(true); // Set this to true after data is loaded
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            setIsUserDataLoaded(true); // Set this to true even if there's an error
        }
    };
 
    const initiateCheckout = async () => {
        mixpanel.track('Get full access', {});

        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch('https://api.getmina.co/api/v1/payment/create-checkout', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({})
            });

            const data = await response.json();

            if (response.ok) {
                const stripe = window.Stripe('pk_live_51PCLezBQK9NeVKEQWkVuPXASDt38HDl8kgGnYWKB9nWxfA8Atp2AeArCagP1UQue3FfktYrYcaISnOoOyOompsVt00Ps2Qs4ay');
                stripe.redirectToCheckout({ sessionId: data.sessionId });
            } else {
                throw new Error(data.error || 'Failed to initiate checkout');
            }
        } catch (error) {
            console.error('Failed to initiate checkout:', error.message);
        }
    };
    useEffect(() => {
        fetchUserData();
        fetchStages();
        fetchComments();
    }, [fetchStages]);
 
    useEffect(() => {
        const handleResize = () => setIsMobileView(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

   

    const getStages = useCallback(() => {
        if (isUserDataLoaded && userStatus !== "active") {
            // Add an extra locked stage for non-active users
            return [...stages, { stage_id: 'locked', is_completed: false }];
        }
        return stages;
    }, [isUserDataLoaded, userStatus, stages]);

    const handleStageClick = (index) => {
        const currentStages = getStages();
        if (userStatus !== "active" && index === currentStages.length - 1) {
            setShowRecommendedStudyModal(true);
        } else if (isStageAccessible(index)) {
            setCurrentStageIndex(index);
            fetchExamsForStage(currentStages[index].stage_id);
        } else {
            console.log("Attempted to access locked stage");
        }
    };

    const getStageClassName = (index) => {
        const currentStages = getStages();
        
        if (index === currentStageIndex) return 'current';
        if (currentStages[index].is_completed) return 'completed';
        // if (index === currentStages.findIndex(stage => !stage.is_completed)) return 'accessible';
        return 'locked';

      
    };

    const getOrdinalSuffix = (number) => {
        if (number === 0) return "Test"; // Add this line
        const j = number % 10,
              k = number % 100;
        if (j === 1 && k !== 11) {
            return number + "st";
        }
        if (j === 2 && k !== 12) {
            return number + "nd";
        }
        if (j === 3 && k !== 13) {
            return number + "rd";
        }
        return number + "th";
    };

    const fetchComments = async () => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch("https://api.getmina.co/api/v1/panel/user/comments", {
                headers: { 'Authorization': `Bearer ${jwt}` }
            });
            const data = await response.json();
            console.log("COMENTS",data);
            setComments(data);
        } catch (error) {
            console.error("Error fetching comments:", error);
        }
    };

    const markCommentAsSeen = async (commentId) => {
        // Remove the comment from the state immediately
        setComments(prevComments => prevComments.filter(comment => comment.comment_id !== commentId));

        try {
            const jwt = localStorage.getItem('jwt');
            await fetch("https://api.getmina.co/api/v1/panel/user/mark-comment-seen", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ comment_id: commentId })
            });
            // No need to update state here as we've already removed the comment
        } catch (error) {
            console.error("Error marking comment as seen:", error);
            // Optionally, you can add the comment back to the state if the API call fails
            // fetchComments(); // Re-fetch all comments
        }
    };

    const getStageTitle = (index) => {
        if (index === 0) {
            return "Your diagnostic test";
        } else {
            return `${getOrdinalSuffix(index)} recommended study`;
        }
    };

    const getStageContent = (index) => {
        if (index === 0) return 'Test';
        const currentStages = getStages();
        if (userStatus !== "active" && index === currentStages.length - 1) {
            return '🔒';
        }
        return getOrdinalSuffix(index);
    };

    function renderExamItem(exam, index) {
        const reviewPath = currentStageIndex === 0 
            ? `/review-personalized/diagnostic` 
            : `/review-personalized/${exam.exam_id}`;

        const examPath = currentStageIndex === 0
            ? `/target-level`
            : `/personalized-exam/${exam.exam_id}`;

        if (exam.is_final_exam) {
            return (
                <div key={exam.exam_id} className="exam-item">
                    <div className="exam-item-box exam-item-locked">
                        <div className="exam-item-locked-container">
                            <div className="exam-item-locked-icon"></div>
                        </div>
                        <div className="exam-item-info">
                            <div className="exam-title-text">Review</div>
                            <div className="exam-subtitle-text">Quiz</div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div key={exam.exam_id} className="exam-item">
                <Link
                    to={exam.completed ? reviewPath : examPath}
                    className={`exam-item-box ${exam.completed ? 'exam-item-completed' : 'exam-item-not-completed'}`}
                >
                    {exam.completed && (
                        <div className="exam-item-checkmark-container">
                            <div className="exam-item-checkmark"></div>
                        </div>
                    )}
                    <div className="exam-item-info">
                        <div className="exam-title-text">
                            {currentStageIndex === 0 ? "Diagnostic Test" : (exam.section ? exam.section.charAt(0).toUpperCase() + exam.section.slice(1) : `Exam ${index + 1}`)}
                        </div>
                        <div className="exam-subtitle-text">
                            {exam.categories || ''}
                        </div>
                    </div>
                </Link>
            </div>
        );
    }

    const ComingSoonModal = ({ onClose }) => (
        <Modal onClose={onClose}>
            <div className="coming-soon-modal">
                <div className="modal-icon">🚀</div>
                <h2>Unlock Full Access!</h2>
                <p>Upgrade now to study with over 5,000 exclusive questions and take your learning to the next level!</p>
                <button className="close-button" onClick={initiateCheckout}>
                   Buy Full Access
                </button>
            </div>
        </Modal>
    );

    // Add this handler function
    // const handleSelectedStudyClick = () => {
    //     if (userStatus === "active") {
    //         navigate('/selected_study');
    //     } else {
    //         setShowComingSoonModal(true);
    //     }
    // };

    return (
        <div className="study-plan-container">
            <TopBar
                isMobileView={isMobileView}
                userStatus={userStatus}
                userData={userData}
                onMenuToggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                onInitiateCheckout={initiateCheckout}
            />

            <MobileMenu
                isOpen={isMobileMenuOpen}
                onClose={() => setIsMobileMenuOpen(false)}
                userStatus={userStatus}
                onInitiateCheckout={initiateCheckout}
                onLogout={handleLogout}
            />

            <div className="progress-bar-container">
                <div className="progress-bar">
                    {getStages().map((stage, index) => (
                        <React.Fragment key={stage.stage_id}>
                            <div 
                                className={`progress-step ${getStageClassName(index)}`}
                                onClick={() => handleStageClick(index)}
                                style={{ cursor: 'pointer' }}
                            >
                                <div className="progress-step-circle">
                                    {getStageContent(index)}
                                </div>
                            </div>
                            {index < getStages().length - 1 && <div className="progress-line"></div>}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="study-title">{getStageTitle(currentStageIndex)}</div>
            {currentStageIndex !== 0 && (
                <div className="analytics-box-container">
                    <button 
                        className="analytics-box"
                        onClick={() => navigate('/stats')}
                    >
                        See All Studies Analytics
                    </button>
                </div>
            )}
            {comments.length > 0 && (
                <div className="comments-section">
                    <div className="comments-list">
                        {comments.map(comment => (
                            <div key={comment.comment_id} className="comment-box">
                                <p>{comment.content}</p>
                                <button 
                                    className="comment-close-btn"
                                    onClick={() => markCommentAsSeen(comment.comment_id)}
                                    aria-label="Remove comment"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <br></br>
            <br></br>
            {loading ? (
                <div className="loader-container"><BeatLoader color="#3E00EF" /></div>
            ) : error ? (
                <div>{error}</div>
            ) : (
                <div className="exams-list-container">
                    {currentExams.map((exam, index) => renderExamItem(exam, index))}
                </div>
            )}
          
            {showLockedExamModal && (
                <LockedExamModal
                    onClose={() => setShowLockedExamModal(false)}
                    onGetFullAccess={() => setShowLockedExamModal(false)}
                />
            )}
            {showRecommendedStudyModal && (
                <Modal onClose={() => setShowRecommendedStudyModal(false)}>
                    <div className="recommended-study-modal">
                        <div className="small-top">Recommended Study</div>
                        <div className="first-title">Unlock personalized JLPT preparation!</div>
                        <p className="sub-title">
                        Mina reviews your answers across all JLPT sections to create study sets just for you, helping you tackle tricky areas and boost your score. Plus, you’ll get helpful feedback and study tips along the way!
                        </p>
                        <div className="space"></div>
                        <button 
                            className="start-test-button"
                            onClick={() => {
                                setShowRecommendedStudyModal(false);
                                initiateCheckout();
                            }}
                        >
                            Get Full Access
                        </button>
                    </div>
                </Modal>
            )}
            {showComingSoonModal && (
                <ComingSoonModal onClose={() => setShowComingSoonModal(false)} />
            )}
        </div>
    );
}

export default StudyPlan;