import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import '../css/ReviewExam.css';
import BeatLoader from 'react-spinners/BeatLoader';
import mixpanel from '../mixpanel';
import timeIcon from '../assets/icons/time.png';
import cancelIcon from '../assets/icons/cancel.png';

function ReviewPersonalizedExam() {
    const { exam_id } = useParams();
    const navigate = useNavigate();
    const [examData, setExamData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    useEffect(() => {
        const fetchExamReview = async () => {
            try {
                setLoading(true);
                const jwt = localStorage.getItem('jwt');
                const apiUrl = exam_id === "diagnostic" 
                    ? `https://api.getmina.co/api/v1/panel/diagnostic/user/responses` 
                    : `https://api.getmina.co/api/v1/panel/personalized-exam/teacher/${exam_id}/responses`;

                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${jwt}`, 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                console.log('API Response:', data);
                setExamData(data);
                mixpanel.track('Review Exam', {
                    exam_id: exam_id,
                    score: calculateScore(data)
                });
            } catch (error) {
                console.error('Error fetching review:', error);
                setError('Failed to fetch exam review.');
            }
            setLoading(false);
        };

        fetchExamReview();
    }, [exam_id]);

    function calculateScore(responses) {
        if (!responses || responses.length === 0) return '0/0';
        const correctAnswers = responses.filter(r => r.is_correct).length;
        return `${correctAnswers}/${responses.length}`;
    }

    const calculateTotalTime = (responses) => {
        if (!responses || responses.length === 0) return 0;
        return responses.reduce((total, response) => total + (response.time_taken || 0), 0);
    };

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .join(":");
    };

    const formatText = (text) => {
        if (!text || text === '0') return '';

        const textWithLineBreaks = text.replace(/{{NEWLINE}}/g, '\n');

        return textWithLineBreaks.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, index) => (
            <span key={index}>
                {part.trim().length === 0 ? <br /> : part.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                        {i > 0 && <br />}
                        {line}
                    </React.Fragment>
                ))}
            </span>
        ));
    };

    const handleQuestionClick = (response, index) => {
        console.log('handleQuestionClick', examData);
        navigate(`/review-question/${exam_id}`, { state: { examData, currentContentIndex: index } });
    };

    const closePopup = () => {
        setSelectedQuestion(null);
    };

    const handleCancelClick = () => {
        navigate('/');
    };

    if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
    if (error) return <div>{error}</div>;
    if (!examData || examData.length === 0) return <div>No exam data available.</div>;

    const score = calculateScore(examData);
    const totalTime = calculateTotalTime(examData);
    const formattedTime = formatTime(totalTime);

    return (
     <div>
          {exam_id === "diagnostic" && (
                <div className="diagnostic-header">
                    
                    <div className="test-result-title">Test result</div>
                    <div className="level-test-results-report">Level test results report</div>
                    <div onClick={handleCancelClick} aria-label="cancel" className="cancel-button">
                        <img src={cancelIcon} alt="Cancel" />
                    </div>
                 
                </div>
            )}
   
        <div className= "page">
               {exam_id !== "diagnostic" && (
             <div onClick={handleCancelClick} aria-label="cancel" className="cancel-button">
                        <img src={cancelIcon} alt="Cancel" />
                    </div>)}


          


            <div className="review-exam">
            {exam_id === "diagnostic" && (
   <div className="diagnostic-nav">
   <Link to="#" className="nav-link active">Problem Explanation</Link>
   <Link to="/diagnostic-stats" className="nav-link">In-depth Analysis</Link>
</div>


            )}
                <div className="header-section">
                    <div className="header-content">
                        <div className="study-results-title">Study results</div>
                        <div className="review-quiz-completed">Review Quiz completed!</div>
                    </div>
                </div>
                <div className="stats-container">
                    <div className="stat-box">
                        <h3>Total study time</h3>
                        <br></br>
                      
                       
                        <div className="stat-value">
                            <img src={timeIcon} alt="Time icon" />
                            {formattedTime}
                        </div>
                        <br></br>
                    </div>
                    <div className="stat-box">

                        <h3>Learning history</h3>
                        <div className="score-container">
                            <div className="score-circle">
                                <span className="score">{score}</span>
                            </div>
                            <span className="score-label">Correct answers</span>
                        </div>
                    </div>
                </div>
                <h3>Answered question results</h3>
                <br></br>
                <div className="exam-responses">
                    {examData.map((response, index) => (
                        <div key={index} className="response-card" onClick={() => handleQuestionClick(response, index)}>
                            <div className="question-header">
                                <h4>Question {index + 1}</h4>
                                <span className="time">{response.time_taken}s</span>
                            </div>
                            <p className="question-text">{response.question_text}</p>
                            <div className="answer-status">
                                {response.is_correct ? (
                                    <svg className="status-icon correcto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                                    </svg>
                                ) : (
                                    <svg className="status-icon incorrecto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                                    </svg>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {selectedQuestion && (
                <div className="question-popup-overlay" onClick={closePopup}>
                    <div className="question-popup" onClick={(e) => e.stopPropagation()}>
                        <div className="popup-header">
                            <h3>Question Details</h3>
                            <button className="close-popup" onClick={closePopup}>&times;</button>
                        </div>
                        <div className="popup-content">
                            {selectedQuestion.instruction && (
                                <div className="instruction">
                                    <strong>Instruction:</strong> {formatText(selectedQuestion.instruction)}
                                </div>
                            )}
                            {selectedQuestion.script && (
                                <div className="script">
                                    <strong>Passage:</strong>
                                    <div className="passage-box">{formatText(selectedQuestion.script)}</div>
                                </div>
                            )}
                            <div className="question">
                                <strong>Question:</strong> {formatText(selectedQuestion.question_text)}
                            </div>
                            <div className="answer-section">
                                {selectedQuestion.choices.map((choice, index) => (
                                    <div
                                        key={index}
                                        className={`answer-option ${
                                            choice === selectedQuestion.selected_answer ? 'selected' : ''
                                        } ${
                                            choice === selectedQuestion.correct_answer ? 'correct' : 
                                            (choice === selectedQuestion.selected_answer && choice !== selectedQuestion.correct_answer) ? 'incorrect' : ''
                                        }`}
                                    >
                                        <span className="choice-number">{index + 1}.</span>
                                        <span className="choice-text">{formatText(choice)}</span>
                                    </div>
                                ))}
                            </div>
                            {selectedQuestion.explanation && (
                                <div className="explanation">
                                    <strong>Explanation:</strong> {formatText(selectedQuestion.explanation)}</div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
        </div>
    );
}

export default ReviewPersonalizedExam;