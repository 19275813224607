import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Login.css';

import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Radio,
  FormControlLabel,
  FormGroup,
  // Link as MuiLink,
  CssBaseline,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import Logo from '../assets/images/logo.png';
import BeatLoader from 'react-spinners/BeatLoader';

const StyledContainer = styled(Container)({
  marginTop: '100px',
  backgroundColor: 'rgb(255, 255, 255)',
  padding: '40px',
  borderRadius: '20px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  position: 'relative',
});

const StyledForm = styled('form')({
  width: '100%',
  marginTop: '1rem',
});

const StyledTextField = styled(TextField)({
  width: '100%',
  marginBottom: '20px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    '& fieldset': {
      borderColor: '#e0e0e0',
    },
    '&:hover fieldset': {
      borderColor: '#bdbdbd',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#757575',
    },
  },
  '& .MuiInputBase-input': {
    padding: '14px',
  },
});

const FieldTitle = styled(Typography)({
  textAlign: 'left',
  marginBottom: '5px',
  fontWeight: 'bold',
});

const StyledButton = styled(Button)({
  marginTop: '1rem',
  marginBottom: '1rem',
  backgroundColor: '#3E00EF',
  color: 'white',
  borderRadius: '5px',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#272499',
  },
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  '&.Mui-disabled': {
    backgroundColor: '#c0c0c0',
    color: '#fff',
  },
});

export default function SignUp() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeToAll: false,
    termsOfUse: false,
    privacyPolicy: false,
    marketingConsent: false,
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' || type === 'radio' ? checked : value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.firstName || !formData.lastName || !formData.username || !formData.email || !formData.password) {
      setErrorMessage('All fields are required.');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    const payload = {
      email: formData.email,
      firstname: formData.firstName,
      lastname: formData.lastName,
      level: "N3",
      password: formData.password,
      username: formData.username
    };

    try {
      const response = await fetch('https://api.getmina.co/api/v1/auth/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const responseData = await response.json();
      setLoading(false);

      if (response.ok) {
        localStorage.setItem('jwt', responseData.access);
        console.log('Registration successful:', responseData);
        navigate('/email-verification'); // Redirect to email verification page
      } else {
        setErrorMessage(responseData.message || 'Failed to register');
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error during registration: ' + error.message);
    }
  };

  return (
    <>
      <Box className="logo-container-box" sx={{ position: 'relative', top: '20px',  }}>
        <img src={Logo} alt="Mina Logo" style={{ height: '50px' }} />
      </Box>
      <StyledContainer component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ width: '100%' }}>
          <Typography component="h1" variant="h5" sx={{ position: 'absolute', top: '20px', left: '20px', margin: 0 }}>
            Sign up
          </Typography>
          <br></br>
          {errorMessage && (
            <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
              {errorMessage}
            </Alert>
          )}
          <StyledForm onSubmit={handleSubmit}>
            <FieldTitle>First Name</FieldTitle>
            <StyledTextField
              required
              fullWidth
              id="firstName"
              name="firstName"
              autoComplete="given-name"
              autoFocus
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
            />
            <FieldTitle>Last Name</FieldTitle>
            <StyledTextField
              required
              fullWidth
              id="lastName"
              name="lastName"
              autoComplete="family-name"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
            />
            <FieldTitle>Username</FieldTitle>
            <StyledTextField
              required
              fullWidth
              id="username"
              name="username"
              autoComplete="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Username"
            />
            <FieldTitle>Email Address</FieldTitle>
            <StyledTextField
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
            />
            <FieldTitle>Password</FieldTitle>
            <StyledTextField
              required
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password (minimum 8 characters)"
            />
            <FieldTitle>Confirm Password</FieldTitle>
            <StyledTextField
              required
              fullWidth
              name="confirmPassword"
              type="password"
              id="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm Password"
            />
            <FormGroup>
              {/* <FormControlLabel
                control={<Radio checked={formData.agreeToAll} onChange={handleChange} name="agreeToAll" />}
                label="Agree to all"
              /> */}
              {/* <FormControlLabel
                control={<Radio checked={formData.termsOfUse} onChange={handleChange} name="termsOfUse" />}
                label={<>
               Terms of Use
                  <MuiLink href="#" sx={{ marginLeft: 1 }}>›</MuiLink>
                </>}
              />
              <FormControlLabel
                control={<Radio checked={formData.privacyPolicy} onChange={handleChange} name="privacyPolicy" />}
                label={<>
                  Privacy Policy
                  <MuiLink href="#" sx={{ marginLeft: 1 }}>›</MuiLink>
                </>} */}
              {/* /> */}
              <FormControlLabel
                control={<Radio checked={formData.marketingConsent} onChange={handleChange} name="marketingConsent" />}
                label="Agree to receive push and marketing/event notifications"
              />
            </FormGroup>
            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading 
                // || !formData.termsOfUse || !formData.privacyPolicy

              }
            >
              {loading ? <BeatLoader color="#fff" size={10} /> : 'Sign Up'}
            </StyledButton>
            <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
              <Link to="/login" style={{ textDecoration: 'none', color: '#5539FF' }}>
                <Typography variant="body2">
                  Already have an account? Log in
                </Typography>
              </Link>
            </Box>
          </StyledForm>
        </Box>
      </StyledContainer>
    </>
  );
}