import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import '../css/personalized_exam.css';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import useMediaQuery from '@mui/material/useMediaQuery';
import cancelIcon from '../assets/icons/cancel.png';
import AskMina from '../AskMina';
import useMediaQuery from '@mui/material/useMediaQuery';

function ReviewQuestion() {
  const navigate = useNavigate();
  const location = useLocation();
  const { exam_id } = useParams();
  // const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [currentContentIndex, setCurrentContentIndex] = useState(location.state?.currentContentIndex || 0);
  const examData = location.state?.examData || [];
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [showAskMina, setShowAskMina] = useState(false);
  const [askMinaKey] = useState(0);

  const totalQuestions = examData.length;
  const remainingQuestions = totalQuestions - currentContentIndex;

  const handlePreviousQuestion = () => {
    if (currentContentIndex > 0) {
      setCurrentContentIndex(currentContentIndex - 1);
    }
  };

  const handleNextQuestion = () => {
    if (currentContentIndex < totalQuestions - 1) {
      setCurrentContentIndex(currentContentIndex + 1);
    }
  };

  const handleBackButtonClick = () => {
    navigate(`/review-personalized/${exam_id}`);
  };

  const handleAskMina = () => {
    setShowAskMina(true);
  };

  const handleCloseAskMina = () => {
    setShowAskMina(false);
  };

  if (examData.length === 0 || !examData[currentContentIndex]) {
    return <div>No content available</div>;
  }

  const { script, question_text, instruction, choices, correct_answer, selected_answer, explanation } = examData[currentContentIndex];

  const formatText = (text) => {
    if (!text || text === '0') return '';

    // First, replace {{NEWLINE}} with actual line breaks
    const textWithLineBreaks = text.replace(/{{NEWLINE}}/g, '\n');

    // Then, split the text and create spans for each part
    return textWithLineBreaks.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, index) => (
      <span key={index}>
        {part.trim().length === 0 ? <br /> : part.split('\n').map((line, i) => (
          <React.Fragment key={i}>
            {i > 0 && <br />}
            {line}
          </React.Fragment>
        ))}
      </span>
    ));
  };

  return (
    <div className="Exam">
      <div className={`upper-bar `}>
        <div className="upper-left">
          <div onClick={handleBackButtonClick} aria-label="cancel" className="cancel-button-exam">
            <img src={cancelIcon} alt="Cancel" />
          </div>
          <div className="questions-remaining">
            {remainingQuestions} questions remaining
          </div>
        </div>
      </div>

      <div className="content">
        {script && script !== "0" ? (
          <div className="two-column-layout">
            <div className="script-column">
              <div className="question-number">Question {currentContentIndex + 1}</div>
              {formatText(script)}
            </div>
            <div className="questions-column">
              {instruction && (
                <div className="instruction-box">
                  <p className="instruction">{formatText(instruction)}</p>
                </div>
              )}
              <div className="question-box">
                <div className="question-text">{formatText(question_text)}</div>
                <div className="answer-section">
                  {choices.map((choice, index) => (
                    <div
                      key={index}
                      className={`answer-option 
                        ${selected_answer === choice ? 'selected' : ''} 
                        ${choice === correct_answer ? 'correct' : (selected_answer === choice ? 'incorrect' : '')}`}
                    >
                      {choice}
                    </div>
                  ))}
                </div>
                {explanation && (
                  <div className="explanation-box">
                    <strong>Explanation:</strong> {formatText(explanation)}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="personalized-page">
            <div className='question-title'> Question {currentContentIndex + 1}</div>
            {instruction && <p>{formatText(instruction)}</p>}
            <hr className="question-divider" />
            <div className="question-answer">
              <div className="question-text">{formatText(question_text)}</div>
              <div className="answer-section">
                {choices.map((choice, index) => (
                  <div
                    key={index}
                    className={`answer-option 
                      ${selected_answer === choice ? 'selected' : ''} 
                      ${choice === correct_answer ? 'correct' : (selected_answer === choice ? 'incorrect' : '')}`}
                  >
                    {choice}
                  </div>
                ))}
              </div>
              {explanation && (
                <div className="explanation-box">
                  <strong>Explanation:</strong> {formatText(explanation)}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className={`lower-bar`}>
        <div className="lower-left"></div>
        {!isSmallScreen && (
          <div className="lower-center">
            <button className="ask-mina-button" onClick={handleAskMina}>Ask Mina</button>
          </div>
        )}
        <div className="lower-right">
          <button className="previous-button" onClick={handlePreviousQuestion}>Previous</button>
          <button className="reveal-answer-button" onClick={handleNextQuestion}>Next</button>
        </div>
      </div>

      {/* Floating Ask Mina button for mobile */}
      {isSmallScreen && (
        <button className="floating-ask-mina-button" onClick={handleAskMina}>
          Ask Mina
        </button>
      )}

      {/* Ask Mina Modal */}
      {showAskMina && (
        <div className="ask-mina-overlay">
          <div className="ask-mina-modal">
            <button className="close-ask-mina" onClick={handleCloseAskMina}>×</button>
            <AskMina
              key={askMinaKey}
              questionIds={[examData[currentContentIndex].question_id]}
              questionnumber={currentContentIndex + 1}
              revealed={true} // Always true in review mode
              initialChatHistory={[]}
              onClose={handleCloseAskMina}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ReviewQuestion;
