import { useEffect } from 'react';

function AdBlockDetector() {
    useEffect(() => {
        const ad = document.createElement('div');
        ad.className = 'ad-unit';
        ad.style.height = '1px';
        document.body.appendChild(ad);
        const adBlocked = !ad.offsetHeight;
        document.body.removeChild(ad);

        if (adBlocked) {
            alert('We have detected that you are using an ad blocker. Please disable it or whitelist our site to ensure all features work correctly.');
        }
    }, []);

    return null;
}

export default AdBlockDetector;
