import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import '../../css/Home.css';

const MobileMenu = ({ 
  isOpen, 
  onClose, 
  userStatus, 
  onInitiateCheckout, 
  onLogout 
}) => (
  <>
    <div 
      className={`mobile-menu-overlay ${isOpen ? 'active' : ''}`} 
      onClick={onClose}
    />
    <div className={`mobile-menu ${isOpen ? 'active' : ''}`}>
      <div className="mobile-menu-header">
        <img src={Logo} alt="InnovateTech Logo" className="mobile-menu-logo" />
      </div>
      <div className="mobile-menu-close" onClick={onClose}>&times;</div>
      <div className="mobile-menu-content">
        <NavLink to="/" className="mobile-menu-item" onClick={onClose}>
          Recommended study
        </NavLink>
        <NavLink to="/selected_study" className="mobile-menu-item" onClick={onClose}>
          Selected study
        </NavLink>
        {userStatus === "inactive" && (
          <button 
            onClick={() => {
              onInitiateCheckout();
              onClose();
            }} 
            className="mobile-menu-item"
          >
            Get full access
          </button>
        )}
        <div className="mobile-menu-spacer" />
        <button 
          onClick={() => {
            onLogout();
            onClose();
          }} 
          className="mobile-menu-logout"
        >
          Logout
        </button>
      </div>
    </div>
  </>
);

export default MobileMenu;