import React from 'react';
import { Link } from 'react-router-dom';
import { formatCategories } from './formatters';
import '../../css/Home.css';

const ExamList = ({ exams }) => (
  <div className="exams-list-container">
    {exams.map((exam, index) => (
      <ExamItem key={exam.exam_id} exam={exam} index={index} />
    ))}
  </div>
);

const ExamItem = ({ exam, index }) => {
  if (exam.isLocked) {
    return (
      <div className="exam-item">
        <div className="exam-item-box exam-item-locked">
          <div className="exam-item-locked-container">
            <div className="exam-item-locked-icon" />
          </div>
          <div className="exam-item-info">
            <div className="exam-title-text">
              {exam.section ? exam.section.charAt(0).toUpperCase() + exam.section.slice(1) : `Exam ${index + 1}`}
            </div>
            <div className="exam-subtitle-text">
              {formatCategories(exam.categories)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const path = exam.completed ? `/review-exam/${exam.exam_id}` : `/exam/${exam.exam_id}`;
  
  return (
    <div className="exam-item">
      <Link
        to={path}
        className={`exam-item-box ${exam.completed ? 'exam-item-completed' : 'exam-item-not-completed'}`}
      >
        {exam.completed && (
          <div className="exam-item-checkmark-container">
            <div className="exam-item-checkmark" />
          </div>
        )}
        <div className="exam-item-info">
          <div className="exam-title-text">
            {exam.section ? exam.section.charAt(0).toUpperCase() + exam.section.slice(1) : `Exam ${index + 1}`}
          </div>
          <div className="exam-subtitle-text">
            {formatCategories(exam.categories)}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ExamList;