import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import '../css/Exam.css';
import BeatLoader from 'react-spinners/BeatLoader';
import AskMina from '../AskMina';
import mixpanel from '../mixpanel';
import useMediaQuery from '@mui/material/useMediaQuery';
import cancelIcon from '../assets/icons/cancel.png';
// import AskMinaIcon from './assets/icons/askminabutton.png';

const SUBMIT_API_URL = "https://api.getmina.co/api/v1/panel/submit-exam";
const REPORT_ISSUE_API_URL = "https://api.getmina.co/api/v1/panel/report-issue";

function Exam() {
  const navigate = useNavigate();
  const location = useLocation();
  const { exam_id } = useParams();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [content, setContent] = useState([]);
  const [currentContentIndex, setCurrentContentIndex] = useState(location.state?.currentContentIndex || 0);
  const [, setIsAnswerRevealed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAskMina, setShowAskMina] = useState(false);
  const [askMinaKey,] = useState(0);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState('');
  const [otherIssueText, setOtherIssueText] = useState('');

  const examIdNumber = Number(exam_id);

  const hasFetchedQuestions = useRef(false);

  const [currentQuestionSet, setCurrentQuestionSet] = useState([]);
  const [currentScriptIndex, setCurrentScriptIndex] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [revealedSets, setRevealedSets] = useState(new Set());

  const fetchQuestions = useCallback(async () => {
    try {
      setLoading(true);
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(`https://api.getmina.co/api/v1/panel/get-questions/${examIdNumber}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
      });
      const data = await response.json();
      console.log(data);
      setContent(data.map(question => ({ ...question, selectedAnswer: null, isAnswered: false })));
      setLoading(false);
    } catch (error) {
      setError("Error fetching questions");
      setLoading(false);
    }
  }, [examIdNumber]);

  


  useEffect(() => {
    if (!hasFetchedQuestions.current) {
      fetchQuestions();
      hasFetchedQuestions.current = true;
    }
  }, [fetchQuestions]);

  const groupQuestionsByScript = useCallback((questions) => {
    const groupedQuestions = {};
    questions.forEach(question => {
      if (!question.script) {
        // For questions without a script, create a unique key
        groupedQuestions[`single_${question.question_id}`] = [question];
      } else if (!groupedQuestions[question.script]) {
        groupedQuestions[question.script] = [];
        groupedQuestions[question.script].push(question);
      } else {
        groupedQuestions[question.script].push(question);
      }
    });
    return Object.values(groupedQuestions);
  }, []);

  useEffect(() => {
    if (content.length > 0) {
      const questionSets = groupQuestionsByScript(content);
      setCurrentQuestionSet(questionSets[currentScriptIndex] || []);
      setCurrentContentIndex(questionSets.slice(0, currentScriptIndex).flat().length);
    }
  }, [content, currentScriptIndex, groupQuestionsByScript]);

  const handleAskMina = () => {
    console.log("question number", currentContentIndex + 1);
    setShowAskMina(true);
  };


  const handleCloseAskMina = () => {
    console.log("question number",currentContentIndex);
    setShowAskMina(false);
  };

  const handleSubmit = async () => {
    console.log({
      exam_id: exam_id,
      responses: Object.entries(answeredQuestions).map(([questionId, answerIndex]) => ({
        question_id: questionId,
        selected_answer: content.find(q => q.question_id.toString() === questionId).choices.choices[answerIndex]
      })),
      
    });
    const requestBody = {
      exam_id: exam_id,
      responses: Object.entries(answeredQuestions).map(([questionId, answerIndex]) => ({
        question_id: questionId,
        selected_answer: content.find(q => q.question_id.toString() === questionId).choices.choices[answerIndex]
      })),
      
    };
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(SUBMIT_API_URL, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to submit exam: ${response.status} ${errorText}`);
      }

      alert("Exam submitted successfully!");
      mixpanel.track('Exam Completed', {
        exam_id: exam_id,
        exam_type: "practice_exam"
      });
      console.log("the response:", await response.text());
      navigate(`/review-exam/${exam_id}`);
    } catch (error) {
      console.error("Failed to submit exam:", error.message);
    }
  };

  const handleAnswerSelection = (questionId, index) => {
    if (!revealedSets.has(currentScriptIndex)) {
      setAnsweredQuestions(prev => ({
        ...prev,
        [questionId]: index
      }));
    }
  };

  const handleRevealAnswer = () => {
    setIsAnswerRevealed(true);
    setRevealedSets(prev => new Set(prev).add(currentScriptIndex));
  };

  const handleNext = () => {
    if (currentScriptIndex < groupQuestionsByScript(content).length - 1) {
      setCurrentScriptIndex(currentScriptIndex + 1);
      setIsAnswerRevealed(revealedSets.has(currentScriptIndex + 1));
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentScriptIndex > 0) {
      setCurrentScriptIndex(currentScriptIndex - 1);
      setIsAnswerRevealed(revealedSets.has(currentScriptIndex - 1));
    }
  };

  const handleBackButtonClick = () => {
    mixpanel.track('Cancel Exam', {
      exam_id: exam_id,
      question_id: content[currentContentIndex]?.question_id,
      question_number: currentContentIndex + 1,
    });
    navigate('/');
  };

  const formatText = (text) => {
    if (!text || text === '0') return ''; // Treat 0 as an empty string

    // Split the text into lines
    const lines = text.split(/\n/);

    // Process each line
    return lines.map((line, lineIndex) => (
      <React.Fragment key={lineIndex}>
        {line.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, partIndex) => (
          <span key={`${lineIndex}-${partIndex}`}>
            {part.trim().length === 0 ? <br /> : part}
          </span>
        ))}
        {lineIndex < lines.length - 1 && <br />} {/* Add extra line break between original lines */}
      </React.Fragment>
    ));
  };

  const handleReportIssue = () => {
    setShowReportModal(true);
  };

  const handleSubmitIssue = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const issueMessage = selectedIssue === "Other" ? otherIssueText : selectedIssue;
      const response = await fetch(REPORT_ISSUE_API_URL, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          question_id: content[currentContentIndex]?.question_id,
          issue_message: issueMessage,
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to submit issue: ${response.status} ${errorText}`);
      }

      alert("Issue reported successfully!");
      setShowReportModal(false);
      setSelectedIssue('');
      setOtherIssueText('');
    } catch (error) {
      console.error("Failed to submit issue:", error.message);
    }
  };

  const closeModal = (e) => {
    if (e.target.className === "report-issue-overlay") {
      setShowReportModal(false);
    }
  };

  if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
  if (error) return <div>{error}</div>;
  if (content.length === 0 || !currentQuestionSet || currentQuestionSet.length === 0) return <div>No content available</div>;

  // const script = currentQuestionSet[0]?.script;
  const totalQuestions = groupQuestionsByScript(content).length;
  const remainingQuestions = totalQuestions - currentScriptIndex;

  return (
    <div className="Exam">
      <div className="upper-bar">
        <div onClick={handleBackButtonClick} aria-label="cancel" className="cancel-button-exam">
          <img src={cancelIcon} alt="Cancel" />
        </div>
        <div className="upper-right">Remaining Questions: {remainingQuestions}</div>
      </div>
      
      <div className="content">
        {currentQuestionSet[0]?.script ? (
          <div className="two-column-layout">
            <div className="script-column">
              <div>
                <div className="question-number" > Question {currentScriptIndex + 1}</div>
                <div className="script-box">
                  {formatText(currentQuestionSet[0].script)}
                </div>
              </div>
            </div>
            <div className="questions-column">
              {currentQuestionSet[0]?.instruction && (
                <div className="instruction-box">
                  <p className="instruction">{formatText(currentQuestionSet[0].instruction)}</p>
                </div>
              )}
              {currentQuestionSet.map((question, index) => (
                <div key={question.question_id} className="question-box">
                  <div className="question-number">Question {currentScriptIndex + 1}.{index + 1}</div>
                  <div className="question-text">{formatText(question.question)}</div>
                  <div className="answer-section">
                    {question.choices.choices.map((choice, choiceIndex) => (
                      <div
                        key={choiceIndex}
                        className={`answer-option 
                          ${answeredQuestions[question.question_id] === choiceIndex ? 'selected' : ''} 
                          ${revealedSets.has(currentScriptIndex) ? (choice === question.answer ? 'correct' : (answeredQuestions[question.question_id] === choiceIndex ? 'incorrect' : '')) : ''}`}
                        onClick={() => handleAnswerSelection(question.question_id, choiceIndex)}
                      >
                        {choice}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="left-section">
            <div className='question-title'>Question {currentScriptIndex + 1}</div>
            {currentQuestionSet[0]?.instruction && (
              <p className="instruction">{formatText(currentQuestionSet[0].instruction)}</p>
            )}
            {currentQuestionSet[0]?.script && currentQuestionSet[0].script !== "0" ? (
              <div className="passage-box">
                {formatText(currentQuestionSet[0].script)}
              </div>
            ) : (
              <hr className="question-divider" />
            )}
            <div className="question-answer">
              <div className="question-text">{formatText(currentQuestionSet[0].question)}</div>
              <div className="answer-section">
                {currentQuestionSet[0].choices.choices.map((choice, choiceIndex) => (
                  <div
                    key={choiceIndex}
                    className={`answer-option 
                      ${answeredQuestions[currentQuestionSet[0].question_id] === choiceIndex ? 'selected' : ''} 
                      ${revealedSets.has(currentScriptIndex) ? (choice === currentQuestionSet[0].answer ? 'correct' : (answeredQuestions[currentQuestionSet[0].question_id] === choiceIndex ? 'incorrect' : '')) : ''}`}
                    onClick={() => handleAnswerSelection(currentQuestionSet[0].question_id, choiceIndex)}
                  >
                    {choice}
                  </div>
                ))}
              </div>
              {/* Add hint and explanation boxes here if needed */}
            </div>
          </div>
        )}
      </div>

      <div className="lower-bar">
        <div className="lower-left">
          <button className="report-issue-button" onClick={handleReportIssue}>Report Issue</button>
        </div>
        {!isSmallScreen && (
          <div className="lower-center">
            <button className="ask-mina-button" onClick={handleAskMina}>Ask Mina</button>
          </div>
        )}
        <div className="lower-right">
          <button className="previous-button" onClick={handlePrevious} disabled={currentScriptIndex === 0}>Previous</button>
          <button 
            className="reveal-answer-button" 
            onClick={revealedSets.has(currentScriptIndex) ? handleNext : handleRevealAnswer}
            disabled={Object.keys(answeredQuestions).filter(id => currentQuestionSet.some(q => q.question_id.toString() === id)).length !== currentQuestionSet.length}
          >
            {revealedSets.has(currentScriptIndex) ? (currentScriptIndex === groupQuestionsByScript(content).length - 1 ? "Finish" : "Next") : "Check Answer"}
          </button>
        </div>
      </div>

      {/* Floating Ask Mina button for mobile */}
      {isSmallScreen && (
        <button className="floating-ask-mina-button" onClick={handleAskMina}>
          Ask Mina
        </button>
      )}
      {showAskMina && (
        <div className="ask-mina-overlay">
          <div className="ask-mina-modal">
            <button className="close-ask-mina" onClick={handleCloseAskMina}>×</button>
            <AskMina
              key={askMinaKey}
              questionIds={currentQuestionSet.map(q => q.question_id)}
              questionnumber={currentContentIndex + 1}
              revealed={revealedSets.has(currentScriptIndex)}
              initialChatHistory={[]}
              onClose={handleCloseAskMina}
            />
          </div>
        </div>
      )}
      {showReportModal && (
        <div className="report-issue-overlay" onClick={closeModal}>
          <div className="report-issue-modal">
            <h2>Report a mistake</h2>
            <hr />
            <p>What's wrong?</p>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="None of the options is correct"
                  checked={selectedIssue === "None of the options is correct"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                None of the options is correct
              </label>
            </div>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="Question has incorrect or missing data"
                  checked={selectedIssue === "Question has incorrect or missing data"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                Question has incorrect or missing data
              </label>
            </div>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="Questions/Options/Infographics are not visible properly or missing"
                  checked={selectedIssue === "Questions/Options/Infographics are not visible properly or missing"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                Questions/Options/Infographics are not visible properly or missing
              </label>
            </div>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="Question or option has a typo"
                  checked={selectedIssue === "Question or option has a typo"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                Question or option has a typo
              </label>
            </div>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="The question is confusing or unclear"
                  checked={selectedIssue === "The question is confusing or unclear"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                The question is confusing or unclear
              </label>
            </div>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="Other"
                  checked={selectedIssue === "Other"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                Other
              </label>
            </div>
            {selectedIssue === "Other" && (
              <textarea
                className="other-issue-input"
                value={otherIssueText}
                onChange={(e) => setOtherIssueText(e.target.value)}
                placeholder="Please describe the issue"
                rows={3}
              />
            )}
            <hr />
            <button 
              className="submit-issue-button" 
              onClick={handleSubmitIssue}
              disabled={selectedIssue === "Other" && otherIssueText.trim() === ""}
            >
              Submit Issue
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Exam;
