import React, { useState, useEffect } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import '../css/Stats.css';
import { useNavigate, Link } from 'react-router-dom';
// import Logo from '../assets/images/logo.png';
import cancelIcon from '../assets/icons/cancel.png';

function DiagnosticStats() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [diagnosticData, setDiagnosticData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchDiagnosticData();
    }, []);

    const fetchDiagnosticData = async () => {
        try {
            const jwt = localStorage.getItem('jwt');
            if (!jwt) {
                throw new Error("JWT token not found in localStorage");
            }
            const response = await fetch('https://api.getmina.co/api/v1/panel/diagnostic/analytics', {
                headers: { 'Authorization': `Bearer ${jwt}` }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            setDiagnosticData(data);
        } catch (error) {
            console.error("Error fetching diagnostic data:", error);
            setError("Error fetching diagnostic data");
        } finally {
            setLoading(false);
        }
    };

    const handleCancelClick = () => {
        navigate('/');
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
    if (error) return <div>{error}</div>;

    const correctAnswersPercentage = (diagnosticData.total_correct / diagnosticData.total_questions) * 100;

    return (
        <div className='diagstats'>
            <div className="diagnostic-header">
                <div className="test-result-title">Test result</div>
                <div className="level-test-results-report">Level test results report</div>
                <div onClick={handleCancelClick} aria-label="cancel" className="cancel-button">
                    <img src={cancelIcon} alt="Cancel" />
                </div>
            </div>
            <div className="stats-wrapper">
                <div className="diagnostic-nav">
                    <Link to="/review-personalized/diagnostic" className="nav-link">Problem Explanation</Link>
                    <Link to="/diagnostic-stats" className="nav-link active">In-depth Analysis</Link>
                </div>
                <div className="page-title-stat">Your Diagnostic Analytics</div>
                <div className="stats-container">
                    <div className="stats-content">
                        {/* Row 1: Large widget on left, small widget on right */}
                        <div className="stats-row">
                            <div className="stats-section large">
                                <div className='score-title'>Overall Score</div>
                                <br></br>
                                <div className="circle-graph" style={{ background: `conic-gradient(#3E00EF ${diagnosticData.overall_score_percentage * 3.6}deg, #f0f0f0 0deg)` }}>
                                    <div className="circle-graph-inner">
                                        {diagnosticData.overall_score_percentage}%
                                    </div>
                                </div>
                            </div>
                            <div className="stats-section large">
                                <h2>Section Scores</h2>
                                <br></br>
                                {Object.entries(diagnosticData.section_scores).map(([section, { score_percentage }], index) => (
                                    <div key={index} className="progress-container">
                                        <div className="progress-label">
                                            <span>{capitalizeFirstLetter(section)}</span>
                                            <span>{score_percentage}%</span>
                                        </div>
                                        <div className="stat-progress-bar">
                                            <div className="progress" style={{ width: `${score_percentage}%` }}></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* Row 2: Small widget on left, large widget on right */}
                        <div className="stats-row">
                            <div className="stats-section small">
                                <h2>Total Questions</h2>
                                <p className="large-number">{diagnosticData.total_questions}</p>
                            </div>
                            <div className="stats-section small">
                                <h2>Total Correct Answers</h2>
                                <div className="small-circle-graph" style={{ background: `conic-gradient(#3E00EF ${correctAnswersPercentage * 3.6}deg, #f0f0f0 0deg)` }}>
                                    <div className="small-circle-graph-inner">
                                        {diagnosticData.total_correct} / {diagnosticData.total_questions}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Row 3: Strengths and Weaknesses */}
                        <div className="stats-row">
                            <div className="stats-section large">
                                <h2>Strengths and Weaknesses</h2>
                                <p className='text'>We are displaying your strengths and weaknesses according to the diagnostic exams you have completed. Take them into consideration to improve your study plan.</p>
                                <br></br>
                                <div className="strengths-weaknesses">
                                    <div>
                                        <h3>Strengths</h3>
                                        <ul>
                                            {diagnosticData.strengths.length > 0 ? (
                                                diagnosticData.strengths.map((strength, index) => (
                                                    <li key={index} className={diagnosticData.strengths.length === 0 ? 'no-bullet' : ''}>
                                                        {capitalizeFirstLetter(strength)}
                                                    </li>
                                                ))
                                            ) : (
                                                <li  className={ 'no-bullet' }>
                                                    No strengths identified yet.
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div>
                                        <h3>Weaknesses</h3>
                                        <ul>
                                            {diagnosticData.weaknesses.length > 0 ? (
                                                diagnosticData.weaknesses.map((weakness, index) => (
                                                    <li key={index} className={diagnosticData.weaknesses.length === 0 ? 'no-bullet' : ''}>
                                                        {capitalizeFirstLetter(weakness)}
                                                    </li>
                                                ))
                                            ) : (
                                                <li  className={ 'no-bullet' }>
                                                    No weaknesses identified yet.
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DiagnosticStats;