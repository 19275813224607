import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './dashboardstyle.css';

function UserExamsPage() {
  const { userId } = useParams();
  const [stages, setStages] = useState([]);
  const [diagnosticExams, setDiagnosticExams] = useState([]);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [isCreatingStage, setIsCreatingStage] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [commentContent, setCommentContent] = useState('');
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(false);

  const navigate = useNavigate();

  const handleDiagnosticExamClick = (examId) => {
    navigate(`/diagnostic-exam-results/${userId}/${examId}`);
  };

  const handlePersonalizedExamClick = (examId) => {
    navigate(`/review-personalized/${examId}`);
  };

  const handleCreateStudyPlan = () => {
    navigate(`/create-study-plan/${userId}`);
  };

  const handleCreateStage = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch('https://api.getmina.co/api/v1/panel/teacher/create-stage', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: parseInt(userId),
        }),
      });

      const data = await response.json();
      if (response.ok) {
        alert('Stage created successfully');
        // Refresh the stages
        fetchExams();
      } else {
        throw new Error(data.message || 'Failed to create stage');
      }
    } catch (error) {
      console.error('Error creating stage:', error);
      alert('Failed to create stage. Please try again.');
    }
  };

  const handleAddComment = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch('https://api.getmina.co/api/v1/panel/teacher/add-comment', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: parseInt(userId),
          content: commentContent,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        alert('Comment added successfully');
        setIsCommentModalOpen(false);
        setCommentContent('');
      } else {
        throw new Error(data.message || 'Failed to add comment');
      }
    } catch (error) {
      console.error('Error adding comment:', error);
      alert('Failed to add comment. Please try again.');
    }
  };

  const handleNotifyUser = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(`https://api.getmina.co/api/v1/panel/teacher/notify-user-new-exams/${userId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log("DATA",data);
      if (response.ok) {
        alert('User notified successfully');
      } else {
        throw new Error(data.message || 'Failed to notify user');
      }
    } catch (error) {
      console.error('Error notifying user:', error);
      alert('Failed to notify user. Please try again.');
    }
  };

  const fetchExams = useCallback(async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      
      // Fetch stages and personalized exams
      const stagesResponse = await fetch(`https://api.getmina.co/api/v1/panel/teacher/user-stages/${userId}`, {
        method: 'GET',
        headers: { 
          'Authorization': `Bearer ${jwt}`
        }
      });
      const stagesData = await stagesResponse.json();
      console.log(stagesData);
      setStages(stagesData);

      // Fetch diagnostic exams
      const diagnosticResponse = await fetch(`https://api.getmina.co/api/v1/panel/diagnostic/admin/${userId}/exams`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${jwt}` }
      });
      const diagnosticData = await diagnosticResponse.json();
      setDiagnosticExams(diagnosticData.filter(exam => exam.completed));

    } catch (error) {
      console.error(error);
      setError('Failed to fetch exams.');
    }
    setLoading(false);
  }, [userId]);

  const fetchComments = useCallback(async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(`https://api.getmina.co/api/v1/panel/teacher/user-comments/${userId}`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${jwt}` }
      });
      const data = await response.json();
      setComments(data);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  }, [userId]);

  useEffect(() => {
    fetchExams();
    fetchComments();
  }, [fetchExams, fetchComments]);

  if (loading) return <div className="loader-container">Loading...</div>;
  if (error) return <div className="error-container">{error}</div>;

  return (
    <div className="container">
      <h1 className="page-title">Exams for User {userId}</h1>
      
      <div className="comments-section">
        <h2 onClick={() => setIsCommentsExpanded(!isCommentsExpanded)} className="expandable-header">
          Comments {isCommentsExpanded ? '▼' : '▶'}
        </h2>
        {isCommentsExpanded && (
          <div className="comments-content">
            <div className="unseen-comments">
              <h3>Unseen Comments</h3>
              {comments.filter(comment => !comment.seen).map(comment => (
                <div key={comment.comment_id} className="comment">
                  <p>{comment.content}</p>
                  <small>By {comment.teacher_name} on {new Date(comment.created_at).toLocaleString()}</small>
                </div>
              ))}
            </div>
            <div className="seen-comments">
              <h3>Seen Comments</h3>
              {comments.filter(comment => comment.seen).map(comment => (
                <div key={comment.comment_id} className="comment">
                  <p>{comment.content}</p>
                  <small>By {comment.teacher_name} on {new Date(comment.created_at).toLocaleString()}</small>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="exam-section">
        <h2>Personalized Exams</h2>
        {stages.length > 0 ? (
          stages.map((stage) => (
            <div key={stage.stage_id} className="stage-section">
              <h3> Stage {stage.order}</h3>
              {stage.exams.length > 0 ? (
                <ul className="exam-list">
                  {stage.exams.map((exam) => (
                    <li key={exam.exam_id} className={`exam-item ${exam.completed ? 'completed' : 'not-completed'}`}>
                      <div 
                        className="exam-link" 
                        onClick={() => exam.completed ? handlePersonalizedExamClick(exam.exam_id) : null}
                      >
                        <span className="exam-id">Exam ID: {exam.exam_id}</span>
                        {exam.is_final_exam && <span className="final-exam-badge">Final Exam</span>}
                        {exam.completed ? (
                          <>
                            <span className="exam-status">Completed</span>
                            {exam.score !== null && <span className="exam-score">Score: {exam.score.toFixed(2)}%</span>}
                          </>
                        ) : (
                          <span className="exam-status">Not Completed</span>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="no-exams">No exams found for this stage.</p>
              )}
            </div>
          ))
        ) : (
          <p className="no-exams">No personalized exams found.</p>
        )}
      </div>

      <div className="exam-section">
        <h2>Diagnostic Exams</h2>
        {diagnosticExams.length > 0 ? (
          <ul className="exam-list">
            {diagnosticExams.map((exam) => (
              <li key={exam.diagnostic_exam_id} className="exam-item completed">
                 <div 
                  className="exam-link" 
                  onClick={() => handleDiagnosticExamClick(exam.diagnostic_exam_id)}
                >
                  <span className="exam-id">Level: {exam.level}</span>
                  <span className="exam-date">Completed on {new Date(exam.date_taken).toLocaleDateString()}</span>
                  {exam.score !== null && <span className="exam-score">Score: {exam.score.toFixed(2)}%</span>}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="no-exams">No completed diagnostic exams found.</p>
        )}
      </div>

      <div className="sticky-footer">
        <button className="create-study-plan-btn" onClick={handleCreateStudyPlan}>
          Create New Study Plan
        </button>
        <button className="create-stage-btn" onClick={handleCreateStage}>
          Add New Stage
        </button>
        <button className="add-comment-btn" onClick={() => setIsCommentModalOpen(true)}>
          Add Comment
        </button>
        <button className="notify-user-btn" onClick={handleNotifyUser}>
          Notify User New Stage
        </button>
      </div>

      {isCommentModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Add Comment</h2>
            <textarea
              value={commentContent}
              onChange={(e) => setCommentContent(e.target.value)}
              placeholder="Enter your comment here"
              rows="4"
            />
            <div className="modal-buttons">
              <button onClick={handleAddComment}>Submit</button>
              <button onClick={() => setIsCommentModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserExamsPage;
