export const getUserProfile = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch("https://api.getmina.co/api/v1/auth/me", {
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      
      if (data.msg === "Token has expired") {
        localStorage.removeItem('jwt');
        window.location.reload();
        return null;
      }
      
      return data;
    } catch (error) {
      console.error("Error fetching user profile:", error);
      throw error;
    }
  };
  
  export const logout = () => {
    localStorage.removeItem('jwt');
  };