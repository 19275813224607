import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import Mina from '../../assets/images/mina.png';
import UserDropdown from '../../components/UserDropdown';
import '../../css/Home.css';


import { FaBars } from 'react-icons/fa';

const TopBar = ({ 
  isMobileView, 
  userStatus, 
  userData, 
  onMenuToggle, 
  onInitiateCheckout 
}) => (
  <div className="topbar-container">
    <div className="topbar-container-left">
      {isMobileView ? (
        <img src={Mina} alt="Mina Logo" className="mina" />
      ) : (
        <img src={Logo} alt="InnovateTech Logo" className="logo" />
      )}
      <FaBars className="menu-icon" onClick={onMenuToggle} />
      <NavLink to="/" className="topbar-link">Recommended study</NavLink>
      <NavLink to="/selected_study" className="topbar-link">Selected study</NavLink>
    </div>
    <div className="topbar-container-right">
      {userStatus === "inactive" && !isMobileView && (
        <button onClick={onInitiateCheckout} className="upgrade-button">
          Get full access
        </button>
      )}
      {userData && <UserDropdown userData={userData} />}
    </div>
  </div>
);

export default TopBar;