import React, { useState, useEffect } from 'react';
import '../css/UserDropdown.css'; // Create and import the CSS for the dropdown

function UserDropdown({ userData }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = React.useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const logout = () => {
    localStorage.removeItem('jwt');
    window.location.reload();
  };

  const initials = `${userData.FirstName.charAt(0)}${userData.LastName.charAt(0)}`;

  return (
    <div className="user-dropdown" ref={dropdownRef}>
      <div className="avatar" onClick={toggleDropdown}>
        {initials}
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-item"><strong>First Name:</strong> {userData.FirstName}</div>
          <div className="dropdown-item"><strong>Last Name:</strong> {userData.LastName}</div>
          <div className="dropdown-item"><strong>Email:</strong> {userData.email}</div>
          <div className="dropdown-item">
            <button onClick={logout} className="logout-button">Logout</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserDropdown;
