import React, { useState } from 'react';

function MyCheckoutComponent() {
    const [error, setError] = useState('');

    const initiateCheckout = async () => {
        try {
            const jwt = localStorage.getItem('jwt');
            const response = await fetch('https://api.getmina.co/api/v1/payment/create-checkout', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  
                })
            });

            const data = await response.json();

            if (response.ok) {
                
const stripe  = window.Stripe('pk_test_51PCLezBQK9NeVKEQ9UZaCLagoap3lORt7iIs0j2mZAmnVBCHh1D3hYr8h9A7UlXVRG8w1PVdaA92qxLDZjOuLRCn002sOLsGHy');
stripe.redirectToCheckout({sessionId:data.sessionId})

            } else {
                throw new Error(data.error || 'Failed to initiate checkout');
            }
        } catch (error) {
            setError(error.message);
            console.error('Failed to initiate checkout:', error.message);
        }
    };

    return (
        <div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <button onClick={initiateCheckout}>Checkout</button>
        </div>
    );
}

export default MyCheckoutComponent;
