// PersonExamDetailPage.js
import React from 'react';
import { useParams , Link} from 'react-router-dom';
import './dashboardstyle.css'; // Import the CSS file

// Example data
const examsData = {
  1: {
    person: "Alice",
    questions: [
      { question: "What is the capital of France?", correctAnswer: "Paris", userAnswer: "Paris" },
      { question: "What is 2 + 2?", correctAnswer: "4", userAnswer: "5" },
    ],
  },
  2: {
    person: "Bob",
    questions: [
      { question: "What is the largest planet?", correctAnswer: "Jupiter", userAnswer: "Mars" },
      { question: "Who wrote 'To Kill a Mockingbird'?", correctAnswer: "Harper Lee", userAnswer: "Harper Lee" },
    ],
  },
};

function PersonExamDetailPage() {
  const { id } = useParams();
  const exam = examsData[id];

  if (!exam) {
    return <div>Exam not found</div>;
  }

  return (
    <div className="container">
      <h1>Exam Results for {exam.person}</h1>
      <ul>
        {exam.questions.map((q, index) => (
          <li key={index}>
            <div className="question">
              <h3>{q.question}</h3>
              {q.correctAnswer === q.userAnswer ? (
                <p className="correct">Correct: {q.correctAnswer}</p>
              ) : (
                <>
                  <p className="incorrect">Your Answer: {q.userAnswer}</p>
                  <p>Correct Answer: {q.correctAnswer}</p>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
      <Link to="/" className="button">Back to People List</Link>
      <br></br>
      <Link to="/create-study-plan" className="button">Create Study Exam</Link>

    </div>
  );
}

export default PersonExamDetailPage;
