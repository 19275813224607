import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/personalized_exam.css'; // Import the personalized exam CSS
import BeatLoader from 'react-spinners/BeatLoader';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import mixpanel from '../mixpanel';
import useMediaQuery from '@mui/material/useMediaQuery';
import cancelIcon from '../assets/icons/cancel.png';

const DIAGNOSTIC_QUESTIONS_API_URL = "https://api.getmina.co/api/v1/panel/diagnostic/get-questions";
const REPORT_ISSUE_API_URL = "https://api.getmina.co/api/v1/panel/report-issue";

function DiagnosticExam() {
  const SUBMIT_API_URL = "https://api.getmina.co/api/v1/panel/diagnostic/submit";
  const navigate = useNavigate();
  const { exam_id } = useParams();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const [responses, setResponses] = useState([]);
  const [content, setContent] = useState([]);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [questionStartTime, setQuestionStartTime] = useState(null);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState('');
  const [otherIssueText, setOtherIssueText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const examIdNumber = Number(exam_id);
  const hasFetchedQuestions = useRef(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoading(true);
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(`${DIAGNOSTIC_QUESTIONS_API_URL}/${examIdNumber}`, {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${jwt}`
          },
        });
        const data = await response.json();
        console.log(data);
        setContent(data.map(question => ({ ...question, selectedAnswer: null, isAnswered: false })));
        setLoading(false);
      } catch (error) {
        setError("Error fetching questions");
        setLoading(false);
      }
    };

    if (!hasFetchedQuestions.current) {
      fetchQuestions();
      hasFetchedQuestions.current = true;
    }
  }, [examIdNumber]);

  useEffect(() => {
    // Set the start time when a new question is displayed
    setQuestionStartTime(Date.now());
  }, [currentContentIndex]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimerSeconds(prev => {
        if (prev === 59) {
          setTimerMinutes(min => min + 1);
          return 0;
        }
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const handleSubmit = async () => {
    if (currentContentIndex === content.length - 1 && !isSubmitting) {
      setIsSubmitting(true);
      const requestBody = { diagnostic_exam_id: exam_id, responses: responses };
      console.log("BODY", requestBody);
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(SUBMIT_API_URL, {
          method: "POST",
          headers: { 'Authorization': `Bearer ${jwt}`, "Content-Type": "application/json" },
          body: JSON.stringify(requestBody)
        });
        console.log("RESPONSE", response.text);
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to submit exam: ${response.status} ${errorText}`);
        }

        alert("Exam submitted successfully!");
        mixpanel.track('Exam Completed', {
          exam_id: exam_id,
          exam_type: "diagnostic_exam"
        });

        navigate(`/diagnostic-stats`);
      } catch (error) {
        console.error("Failed to submit exam:", error.message);
        setIsSubmitting(false); // Reset submitting state if there's an error
      }
    }
  };

  const handleAnswerSelection = (index) => {
    const timeTaken = Math.round((Date.now() - questionStartTime) / 1000);

    // Update content array with the selected answer
    const updatedContent = content.map((question, idx) =>
      idx === currentContentIndex ? { ...question, selectedAnswer: index } : question
    );
    setContent(updatedContent);

    // Update responses array with the new answer
    const questionId = content[currentContentIndex].question_id;
    const selectedAnswer = content[currentContentIndex].choices.choices[index];

    const newResponses = responses.filter(response => response.question_id !== questionId);
    newResponses.push({
      question_id: questionId,
      selected_answer: selectedAnswer,
      time_taken: timeTaken
    });
    setResponses(newResponses);
  };

  const handleNextQuestion = () => {
    if (currentContentIndex < content.length - 1) {
      setCurrentContentIndex(currentContentIndex + 1);
      setQuestionStartTime(Date.now());
    } else {
      handleSubmit();
    }
  };

  const handlePreviousQuestion = () => {
    if (currentContentIndex > 0) {
      setCurrentContentIndex(currentContentIndex - 1);
    }
  };

  const handleBackButtonClick = () => {
    mixpanel.track('Cancel Exam', { exam_id: exam_id, question_id: content[currentContentIndex]?.question_id, question_number: currentContentIndex + 1 });
    navigate('/');
  };

  const formatText = (text) => {
    if (!text || text === '0') return '';
    return text.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, index) => (
      <span key={index}>{part.trim().length === 0 ? <br /> : part}</span>
    ));
  };

  const handleReportIssue = () => {
    setShowReportModal(true);
  };

  const handleSubmitIssue = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const issueMessage = selectedIssue === "Other" ? otherIssueText : selectedIssue;
      const response = await fetch(REPORT_ISSUE_API_URL, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          question_id: content[currentContentIndex]?.question_id,
          issue_message: issueMessage,
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to submit issue: ${response.status} ${errorText}`);
      }

      alert("Issue reported successfully!");
      setShowReportModal(false);
      setSelectedIssue('');
      setOtherIssueText('');
    } catch (error) {
      console.error("Failed to submit issue:", error.message);
    }
  };

  const closeModal = (e) => {
    if (e.target.className === "report-issue-overlay") {
      setShowReportModal(false);
    }
  };

  if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;

  if (error) {
    return <div>{error}</div>;
  }

  if (content.length === 0 || !content[currentContentIndex]) {
    return <div>No content available</div>;
  }

  const questionNumber = currentContentIndex + 1;
  const { script, question, instruction, choices,} = content[currentContentIndex];

  return (
    <div className="Exam">
      <>
        <div className={`upper-bar`}>
          <div className="upper-left">
            <div onClick={handleBackButtonClick} aria-label="cancel" className="cancel-button-exam">
              <img src={cancelIcon} alt="Cancel" />
            </div>
            <div className="questions-remaining">
              {content.length - currentContentIndex} questions remaining
            </div>
          </div>
          <div className="upper-right">
            <div className="timer">
              {timerMinutes}:{timerSeconds < 10 ? `0${timerSeconds}` : timerSeconds}
            </div>
          </div>
        </div>

        <div className="content">
          {isSmallScreen ? (
            <>
              <div className="right-section">
                <div className='question-title'> Question {questionNumber}</div>
                {instruction && <p>{formatText(instruction)}</p>}
                <br />
                {script && script !== "0" && (
                  <>
                    <div className="passage-box">
                      {formatText(script)}
                    </div>
                  </>
                )}
                <br />

                <div className="question-answer">
                  <div className="question-text">{formatText(question)}</div>
                  <div className="answer-section">
                    {choices.choices.map((choice, index) => (
                      <div
                        key={index}
                        className={`answer-option ${content[currentContentIndex].selectedAnswer === index ? 'selected' : ''}`}
                        onClick={() => handleAnswerSelection(index)}
                      >
                        {choice}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="personalized-page">
                <div className='question-title'> Question {questionNumber}</div>
                {instruction && <p>{formatText(instruction)}</p>}
                {script && script !== "0" ? (
                  <div className="passage-box">
                    {formatText(script)}
                  </div>
                ) : (
                  <hr className="question-divider" />
                )}
                <div className="question-answer">
                  <div className="question-text">{formatText(question)}</div>
                  <div className="answer-section">
                    {choices.choices.map((choice, index) => (
                      <div
                        key={index}
                        className={`answer-option ${content[currentContentIndex].selectedAnswer === index ? 'selected' : ''}`}
                        onClick={() => handleAnswerSelection(index)}
                      >
                        {choice}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className={`lower-bar`}>
          <button className="report-issue-button" onClick={handleReportIssue}>Report Issue</button>
          <div className="lower-left"></div>
          <div className="lower-right">
            <button 
              className="previous-button" 
              onClick={handlePreviousQuestion}
              disabled={currentContentIndex === 0}
            >
              Previous
            </button>
            <button 
              className="reveal-answer-button" 
              onClick={handleNextQuestion}
              disabled={content[currentContentIndex].selectedAnswer === null || isSubmitting}
            >
              {currentContentIndex === content.length - 1 ? (isSubmitting ? "Submitting..." : "Finish") : "Next"}
            </button>
          </div>
        </div>
      </>
      
      {showReportModal && (
        <div className="report-issue-overlay" onClick={closeModal}>
          <div className="report-issue-modal">
            <h2>Report Issue</h2>
            <hr />
            <p>What's wrong?</p>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="None of the options is correct"
                  checked={selectedIssue === "None of the options is correct"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                None of the options is correct
              </label>
            </div>
            <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="Question has incorrect or missing data"
                  checked={selectedIssue === "Question has incorrect or missing data"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  Question has incorrect or missing data
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="Questions/Options/Infographics are not visible properly or missing"
                  checked={selectedIssue === "Questions/Options/Infographics are not visible properly or missing"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  Questions/Options/Infographics are not visible properly or missing
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="Question or option has a typo"
                  checked={selectedIssue === "Question or option has a typo"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  Question or option has a typo
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="The question is confusing or unclear"
                  checked={selectedIssue === "The question is confusing or unclear"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  The question is confusing or unclear
                  </label>
                  </div>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="Other"
                  checked={selectedIssue === "Other"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                />
                Other
              </label>
            </div>
            {selectedIssue === "Other" && (
              <textarea
                className="other-issue-input"
                value={otherIssueText}
                onChange={(e) => setOtherIssueText(e.target.value)}
                placeholder="Please describe the issue"
                rows={3}
              />
            )}
            <hr />
            <button 
              className="submit-issue-button" 
              onClick={handleSubmitIssue}
              disabled={selectedIssue === "Other" && otherIssueText.trim() === ""}
            >
              Submit Issue
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DiagnosticExam;
