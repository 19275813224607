import React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import '../../css/Home.css';

const SectionSelector = ({
  sections,
  activeSection,
  isDropdownOpen,
  onSectionChange,
  onDropdownToggle
}) => (
  <>
    <div className="section-selector desktop-only">
      <div className="section-titles">
        {sections.map((section, index) => (
          <div
            key={index}
            className={`section-title ${activeSection === section.name ? 'active' : ''}`}
            onClick={() => onSectionChange(section.name)}
          >
            {section.name}
          </div>
        ))}
      </div>
    </div>

    <div className="section-dropdown-container">
      <div className="section-dropdown" onClick={onDropdownToggle}>
        <span>{activeSection}</span>
        <FaChevronDown className={`dropdown-icon ${isDropdownOpen ? 'open' : ''}`} />
      </div>
      {isDropdownOpen && (
        <div className="dropdown-menu">
          {sections.map((section, index) => (
            <div
              key={index}
              className={`dropdown-item ${activeSection === section.name ? 'active' : ''}`}
              onClick={() => {
                onSectionChange(section.name);
                onDropdownToggle();
              }}
            >
              {section.name}
            </div>
          ))}
        </div>
      )}
    </div>
  </>
);

export default SectionSelector;