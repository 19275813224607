import React, { useState, useEffect } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import '../css/Stats.css';
// import { NavLink } from 'react-router-dom';
// import Logo from '../assets/images/logo.png';
// import { FaBars } from 'react-icons/fa';
// import UserDropdown from '../components/UserDropdown';
import cancelIcon from '../assets/icons/cancel.png';

function Stats() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [analyticsData, setAnalyticsData] = useState(null);
    // const [ setUserData] = useState(null);
    // const [ setUserStatus] = useState(null);
    // const [, setShowReferralModal] = useState(false);
    // const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    // const fetchUserData = async () => {
    //     try {
    //         const jwt = localStorage.getItem('jwt');
    //         const response = await fetch("https://api.getmina.co/api/v1/auth/me", {
    //             method: "GET",
    //             headers: {
    //                 'Authorization': `Bearer ${jwt}`,
    //                 "Content-Type": "application/json"
    //             },
    //         });

    //         const meData = await response.json();

    //         if (meData.msg === "Token has expired") {
    //             localStorage.removeItem('jwt');
    //             window.location.reload();
    //         } else {
    //             setUserStatus(meData.status);
    //             setUserData(meData);
    //             // mixpanel.identify(meData.user_id);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching user data:", error);
    //     }
    // };
    useEffect(() => {
        fetchAnalyticsData();
        // fetchUserData();
    }, []);

    const fetchAnalyticsData = async () => {
        try {
            const jwt = localStorage.getItem('jwt');
            if (!jwt) {
                throw new Error("JWT token not found in localStorage");
            }
            const response = await fetch('https://api.getmina.co/api/v1/panel/user/analytics', {
                headers: { 'Authorization': `Bearer ${jwt}` }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log(data);
            setAnalyticsData(data);
        } catch (error) {
            console.error("Error fetching analytics data:", error);
            setError("Error fetching analytics data");
        } finally {
            setLoading(false);
        }
    };

    // const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    if (loading) return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
    if (error) return <div>{error}</div>;

    // Handle the case where analyticsData is null or undefined
    if (!analyticsData) return <div>No analytics data available.</div>;

    const correctAnswersPercentage = analyticsData.total_questions > 0
        ? (analyticsData.total_correct_answers / analyticsData.total_questions) * 100
        : 0;

    const handleCancelClick = () => {
        // Navigate to the home page or wherever you want
        window.location.href = '/';
    };

    return (
        <div className="stats-wrapper">
            <div onClick={handleCancelClick} aria-label="cancel" className="cancel-button">
                <img src={cancelIcon} alt="Cancel" />
            </div>
            <div className="page-title-stat">Your Study Analytics</div>
            <div className="stats-container">
                <div className="stats-content">
                    {/* Row 1: Large widget on left, small widget on right */}
                    <div className="stats-row">
                        <div className="stats-section large">
                            <div className='score-title'>Average Score</div>
                            <br></br>
                            <div className="circle-graph" style={{ background: `conic-gradient(#3E00EF ${(analyticsData.overall_score_percentage || 0) * 3.6}deg, #f0f0f0 0deg)` }}>
                                <div className="circle-graph-inner">
                                    {analyticsData.overall_score_percentage || 0}%
                                </div>
                            </div>
                        </div>
                        <div className="stats-section large">
                            <h2>Score Distribution</h2>
                            <br></br>
                            {analyticsData.average_scores && analyticsData.average_scores.sections && Object.entries(analyticsData.average_scores.sections).length > 0 ? (
                                Object.entries(analyticsData.average_scores.sections).map(([section, score], index) => (
                                    <div key={index} className="progress-container">
                                        <div className="progress-label">
                                            <span>{capitalizeFirstLetter(section)}</span>
                                            <span>{score.toFixed(2)}%</span>
                                        </div>
                                        <div className="stat-progress-bar">
                                            <div className="progress" style={{ width: `${score}%` }}></div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No score distribution data available.</p>
                            )}
                        </div>
                    </div>
                    {/* Row 2: Small widget on left, large widget on right */}
                    <div className="stats-row">
                        <div className="stats-section small">
                            <h2>Total Personalized Studies Completed</h2>
                            <p className="large-number">{analyticsData.total_personalized_exams || 0}</p>
                        </div>
                        <div className="stats-section small">
                            <h2>Total Correct Answers</h2>
                            <div className="small-circle-graph" style={{ background: `conic-gradient(#3E00EF ${correctAnswersPercentage * 3.6}deg, #f0f0f0 0deg)` }}>
                                <div className="small-circle-graph-inner">
                                    {analyticsData.total_correct_answers || 0} / {analyticsData.total_questions || 0}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Row 3: Strengths and Weaknesses */}
                    <div className="stats-row">
                        <div className="stats-section large">
                            <h2>Strengths and Weaknesses</h2>
                            <p className='text'>We display your strengths and weaknesses based on the recommended studies you've completed. These will update as you progress, and each new set of recommended studies will be tailored to the quality of your answers.</p>
                            <br></br>
                            <div className="strengths-weaknesses">
                                <div>
                                    <h3>Strengths</h3>
                                    <ul>
                                        {analyticsData.strengths && analyticsData.strengths.length > 0 ? (
                                            analyticsData.strengths.map((strength, index) => (
                                                <li key={index}>{capitalizeFirstLetter(strength)}</li>
                                            ))
                                        ) : (
                                            <li className='no-bullet'>No strengths identified yet.</li>
                                        )}
                                    </ul>
                                </div>
                                <div>
                                    <h3>Weaknesses</h3>
                                    <ul>
                                        {analyticsData.weaknesses && analyticsData.weaknesses.length > 0 ? (
                                            analyticsData.weaknesses.map((weakness, index) => (
                                                <li key={index}>{capitalizeFirstLetter(weakness)}</li>
                                            ))
                                        ) : (
                                            <li className='no-bullet'>No weaknesses identified yet.</li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stats;