import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/RegistrationSteps.css';
import '../css/Login.css';
import Logo from '../assets/images/logo.png';

import {
  Box,
 
} from '@mui/material';
function TargetLevelStep() {
  const [targetLevel, setTargetLevel] = useState(null);
  const navigate = useNavigate();

  const levels = ['N5', 'N4', 'N3', 'N2', 'N1'];

  const handleContinue = () => {
    // Store the target level information
    localStorage.setItem('selectedLevel', targetLevel);
    console.log("MYTARGET LECEL",targetLevel);
    navigate('/level-test');
  };

  return (
    <div >
    <Box className="logo-container-box" sx={{ position: 'relative', top: '0px',  }}>
    <img src={Logo} alt="Mina Logo" style={{ height: '50px' }} />
  </Box>
  <div className='check-body'>
    <div className="registration-step">
      <div className="step-indicator">2/2</div>
      <div className="first-title">What is your target level?</div>
      <p className="sub-title">Mina has acquired data on the most recent actual JLPTs, which will help you achieve your target score.</p>
      <div className="level-buttons">
        {levels.map((level) => (
          <div key={level} className="level-button">
            <input
              type="radio"
              id={level}
              name="targetLevel"
              value={level}
              checked={targetLevel === level}
              onChange={() => setTargetLevel(level)

              }
            />
            <label htmlFor={level}>Level {level}</label>
          </div>
        ))}
      </div>
      <button 
        className={`continue-button ${targetLevel !== null ? 'active' : ''}`}
        onClick={handleContinue}
        disabled={targetLevel === null}
      >
        Continue
      </button>
    </div>
    </div>
    </div>
  );
}

export default TargetLevelStep;