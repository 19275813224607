import React, { useState } from 'react';
import dropdown from '../assets/icons/dropdown_icon.png';
import '../css/Dropdown.css';

function Dropdown({ options, selected, onChange }) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);

    return (
        <div className="dropdown-container">
            <button className="dropdown-button" onClick={toggleDropdown}>
                <span>{selected}</span>
                <img src={dropdown}  alt="dropdown" className="dropdown-icon" />
            </button>
            {isOpen && (
                <ul className="dropdown-list">
                    {options.map(option => (
                        <li
                            key={option}
                            className="dropdown-item"
                            onClick={() => {
                                onChange(option);
                                setIsOpen(false);
                            }}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Dropdown;
