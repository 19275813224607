import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import '../css/personalized_exam.css';
import BeatLoader from 'react-spinners/BeatLoader';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import mixpanel from '../mixpanel';
// import useMediaQuery from '@mui/material/useMediaQuery';
import cancelIcon from '../assets/icons/cancel.png';

const GET_PERSONALIZED_EXAM_API_URL = "https://api.getmina.co/api/v1/panel/personalized-exam";
const SUBMIT_PERSONALIZED_EXAM_API_URL = "https://api.getmina.co/api/v1/panel/personalized-exam/submit";
const REPORT_ISSUE_API_URL = "https://api.getmina.co/api/v1/panel/report-issue";

function PersonalizedExam() {
  const navigate = useNavigate();
  const location = useLocation();
  const { exam_id } = useParams();
  // const isSmallScreen = useMediaQuery('(max-width: 768px)');
  // const [responses, setResponses] = useState([]);
  const [content, setContent] = useState([]);
  const [currentContentIndex, ] = useState(location.state?.currentContentIndex || 0);
  // const [, setUserSelectedAnswer] = useState(null);
  // const [, setRevealAnswer] = useState(false);
  const [, setIsAnswerRevealed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [, setHintReceived] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState('');
  const [otherIssueText, setOtherIssueText] = useState('');
  const [, setQuestionStartTime] = useState(null);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);

  const examIdNumber = Number(exam_id);
  const hasFetchedQuestions = useRef(false);

  const [currentQuestionSet, setCurrentQuestionSet] = useState([]);
  const [currentScriptIndex, setCurrentScriptIndex] = useState(0);

  const [answeredQuestions, setAnsweredQuestions] = useState({});
  const [revealedSets, setRevealedSets] = useState(new Set());

  const [groupedQuestions, setGroupedQuestions] = useState([]);

  const fetchQuestions = useCallback(async () => {
    try {
      setLoading(true);
      const jwt = localStorage.getItem('jwt');
      const response = await fetch(`${GET_PERSONALIZED_EXAM_API_URL}/${examIdNumber}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
      });

      const data = await response.json();
      console.log("API response:", data);

      if (data && data.responses && Array.isArray(data.responses)) {
        setContent(data.responses.map(question => ({ ...question, selectedAnswer: null, isAnswered: false })));
      } else {
        console.error("Unexpected data structure:", data);
        setError("Unexpected data structure received from the server");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching questions:", error);
      setError("Error fetching questions");
      setLoading(false);
    }
  }, [examIdNumber]);

  useEffect(() => {
    if (!hasFetchedQuestions.current) {
      fetchQuestions();
      hasFetchedQuestions.current = true;
    }
    // Set the start time when a new question is displayed
    setQuestionStartTime(Date.now());
  }, [fetchQuestions, currentContentIndex]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimerSeconds(prev => {
        if (prev === 59) {
          setTimerMinutes(min => min + 1);
          return 0;
        }
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  // const totalQuestions = content.length;
  const remainingQuestions = groupedQuestions.length - currentScriptIndex;

  const handleSubmit = async () => {
    const allQuestions = content.flat();
    const requestBody = {
      exam_id: exam_id,
      responses: allQuestions.map(question => {
        const answerIndex = answeredQuestions[question.question_id];
        return {
          question_id: question.question_id,
          selected_answer: answerIndex !== undefined ? question.choices[answerIndex] : null
        };
      }).filter(response => response.selected_answer !== null)
    };

    try {
      const jwt = localStorage.getItem('jwt');
      console.log("HELO",requestBody);
      const response = await fetch(SUBMIT_PERSONALIZED_EXAM_API_URL, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to submit exam: ${response.status} - ${JSON.stringify(errorData)}`);
      }

      alert("Exam submitted successfully!");
      mixpanel.track('Exam Completed', {
        exam_id: exam_id,
        exam_type: "personalized_exam"
      });
      navigate(`/review-personalized/${exam_id}`);
    } catch (error) {
      console.error("Failed to submit exam:", error.message);
      alert(`Failed to submit exam: ${error.message}`);
    }
  };

  const handleAnswerSelection = (questionId, index) => {
    if (!revealedSets.has(currentScriptIndex)) {
      setAnsweredQuestions(prev => ({
        ...prev,
        [questionId]: index
      }));
    }
  };

  const handleRevealAnswer = () => {
    setIsAnswerRevealed(true);
    setRevealedSets(prev => new Set(prev).add(currentScriptIndex));
  };

  // const handlePreviousQuestion = () => {
  //   if (currentContentIndex > 0) {
  //     const previousContentIndex = currentContentIndex - 1;
  //     setCurrentContentIndex(previousContentIndex);
  //     setUserSelectedAnswer(content[previousContentIndex]?.selectedAnswer);
  //     setRevealAnswer(true);
  //     setIsAnswerRevealed(true);
  //     setHintReceived(false);
  //   }
  // };

  const handleBackButtonClick = () => {
    mixpanel.track('Cancel Personalized Exam', {
      exam_id: exam_id,
      question_id: content[currentContentIndex]?.question_id,
      question_number: currentContentIndex + 1,
    });
    navigate('/');
  };

  const formatText = (text) => {
    if (!text || text === '0') return '';

    // First, replace {{NEWLINE}} with actual line breaks
    const textWithLineBreaks = text.replace(/{{NEWLINE}}/g, '\n');

    // Then, split the text and create spans for each part
    return textWithLineBreaks.split(/(?<!\S)(\s{2,})(?!\S)(?!.*\))/g).map((part, index) => (
      <span key={index}>
        {part.trim().length === 0 ? <br /> : part.split('\n').map((line, i) => (
          <React.Fragment key={i}>
            {i > 0 && <br />}
            {line}
          </React.Fragment>
        ))}
      </span>
    ));
  };

  const handleReportIssue = () => {
    setShowReportModal(true);
  };

  const handleSubmitIssue = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const issueMessage = selectedIssue === "Other" ? otherIssueText : selectedIssue;
      const response = await fetch(REPORT_ISSUE_API_URL, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          question_id: content[currentContentIndex]?.question_id,
          issue_message: issueMessage,
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to submit issue: ${response.status} ${errorText}`);
      }

      alert("Issue reported successfully!");
      setShowReportModal(false);
      setSelectedIssue('');
      setOtherIssueText('');
    } catch (error) {
      console.error("Failed to submit issue:", error.message);
    }
  };

  const closeModal = (e) => {
    if (e.target.className === "report-issue-overlay") {
      setShowReportModal(false);
    }
  };

  const groupQuestionsByScript = useCallback((questions) => {
    const groupedQuestions = {};
    questions.forEach(question => {
      if (!question.script || question.script === "0") {
        // For questions without a script or script "0", create a unique key
        groupedQuestions[`single_${question.question_id}`] = [question];
      } else if (!groupedQuestions[question.script]) {
        groupedQuestions[question.script] = [];
        groupedQuestions[question.script].push(question);
      } else {
        groupedQuestions[question.script].push(question);
      }
    });
    return Object.values(groupedQuestions);
  }, []);

  useEffect(() => {
    if (content.length > 0) {
      const questionSets = groupQuestionsByScript(content);
      setGroupedQuestions(questionSets);
      setCurrentQuestionSet(questionSets[currentScriptIndex] || []);
    }
  }, [content, currentScriptIndex, groupQuestionsByScript]);

  const handleNext = () => {
    if (currentScriptIndex < groupQuestionsByScript(content).length - 1) {
      setCurrentScriptIndex(currentScriptIndex + 1);
      setIsAnswerRevealed(revealedSets.has(currentScriptIndex + 1));
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentScriptIndex > 0) {
      setCurrentScriptIndex(currentScriptIndex - 1);
      setIsAnswerRevealed(true);
      setHintReceived(false);
    }
  };

  if (loading) {
    return <div className="loader-container"><BeatLoader color="#3E00EF" /></div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!currentQuestionSet || currentQuestionSet.length === 0) {
    return <div className="error-message">No questions available.</div>;
  }

  const currentQuestion = currentQuestionSet[0];

  return (
    <div className="Exam">
      <>
        <div className={`upper-bar `}>
          <div className="upper-left">
            <div onClick={handleBackButtonClick} aria-label="cancel" className="cancel-button-exam">
              <img src={cancelIcon} alt="Cancel" />
            </div>
            <div className="questions-remaining">
              {remainingQuestions} questions remaining
            </div>
          </div>
          <div className="upper-right">
            <div className="timer">
              {timerMinutes}:{timerSeconds < 10 ? `0${timerSeconds}` : timerSeconds}
            </div>
          </div>
        </div>

        <div className="content">
          {currentQuestion?.script && currentQuestion.script !== "0" ? (
            <div className="two-column-layout">
              <div className="script-column">
                <div className="question-number">Question {currentScriptIndex + 1}</div>
                <div className="script-box">
                  {formatText(currentQuestion.script)}
                </div>
              </div>
              <div className="questions-column">
                {currentQuestion?.instruction && (
                  <div className="instruction-box">
                    <p className="instruction">{formatText(currentQuestion.instruction)}</p>
                  </div>
                )}
                {currentQuestionSet.map((question, index) => (
                  <div key={question.question_id} className="question-box">
                    <div className="question-number">Question {currentScriptIndex + 1}.{index + 1}</div>
                    <div className="question-text">{formatText(question.question)}</div>
                    <div className="answer-section">
                      {question.choices.map((choice, choiceIndex) => (
                        <div
                          key={choiceIndex}
                          className={`answer-option 
                            ${answeredQuestions[question.question_id] === choiceIndex ? 'selected' : ''} 
                            ${revealedSets.has(currentScriptIndex) 
                              ? (choice === question.correct_answer 
                                  ? 'correct' 
                                  : (answeredQuestions[question.question_id] === choiceIndex 
                                      ? 'incorrect' 
                                      : ''))
                              : ''}`}
                          onClick={() => handleAnswerSelection(question.question_id, choiceIndex)}
                        >
                          {choice}
                        </div>
                      ))}
                    </div>
                    {revealedSets.has(currentScriptIndex) && question.explanation && (
                      <div className="explanation-box">
                        <strong>Explanation:</strong> {question.explanation}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="left-section">
              <div className='question-title'>Question {currentScriptIndex + 1}</div>
              {currentQuestion?.instruction && (
                <p className="instruction">{formatText(currentQuestion.instruction)}</p>
              )}
              <hr className="question-divider" />
              <div className="question-answer">
                <div className="question-text">{formatText(currentQuestion.question)}</div>
                <div className="answer-section">
                  {currentQuestion.choices.map((choice, choiceIndex) => (
                    <div
                      key={choiceIndex}
                      className={`answer-option 
                        ${answeredQuestions[currentQuestion.question_id] === choiceIndex ? 'selected' : ''} 
                        ${revealedSets.has(currentScriptIndex) 
                          ? (choice === currentQuestion.correct_answer 
                              ? 'correct' 
                              : (answeredQuestions[currentQuestion.question_id] === choiceIndex 
                                  ? 'incorrect' 
                                  : ''))
                          : ''}`}
                      onClick={() => handleAnswerSelection(currentQuestion.question_id, choiceIndex)}
                    >
                      {choice}
                    </div>
                  ))}
                </div>
                {revealedSets.has(currentScriptIndex) && currentQuestion.explanation && (
                  <div className="explanation-box">
                    <strong>Explanation:</strong> {currentQuestion.explanation}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={`lower-bar`}>
          {/* {isSmallScreen && (
            <button className="report-issue-button" onClick={handleReportIssue}>Report Issue</button>
          )} */}
             <button className="report-issue-button" onClick={handleReportIssue}>Report Issue</button>
             <div className="lower-left"></div>

          <div className="lower-right">
            <button className="previous-button" onClick={handlePrevious} disabled={currentScriptIndex === 0}>Previous</button>
            <button 
              className="reveal-answer-button" 
              onClick={revealedSets.has(currentScriptIndex) ? handleNext : handleRevealAnswer}
              disabled={!currentQuestionSet.every(q => answeredQuestions[q.question_id] !== undefined)}
            >
              {revealedSets.has(currentScriptIndex) ? (currentScriptIndex === groupQuestionsByScript(content).length - 1 ? "Finish" : "Next") : "Check Answer"}
            </button>
          </div>
        </div>

      
      </>
 
      {showReportModal && (
        <div className="report-issue-overlay" onClick={closeModal}>
          <div className="report-issue-modal">
            <h2>Report Issue</h2>
            <hr />
            <p>What's wrong?</p>
            <div className="issue-option">
              <label>
                <input
                  type="radio"
                  name="issue"
                  value="None of theoptions is correct"
                  checked={selectedIssue === "None of the options is correct"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  None of the options is correct
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="Question has incorrect or missing data"
                  checked={selectedIssue === "Question has incorrect or missing data"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  Question has incorrect or missing data
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="Questions/Options/Infographics are not visible properly or missing"
                  checked={selectedIssue === "Questions/Options/Infographics are not visible properly or missing"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  Questions/Options/Infographics are not visible properly or missing
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="Question or option has a typo"
                  checked={selectedIssue === "Question or option has a typo"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  Question or option has a typo
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="The question is confusing or unclear"
                  checked={selectedIssue === "The question is confusing or unclear"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  The question is confusing or unclear
                  </label>
                  </div>
                  <div className="issue-option">
                  <label>
                  <input
                  type="radio"
                  name="issue"
                  value="Other"
                  checked={selectedIssue === "Other"}
                  onChange={(e) => setSelectedIssue(e.target.value)}
                  />
                  Other
                  </label>
                  </div>
                  {selectedIssue === "Other" && (
                    <textarea
                      className="other-issue-input"
                      value={otherIssueText}
                      onChange={(e) => setOtherIssueText(e.target.value)}
                      placeholder="Please describe the issue"
                      rows={3}
                    />
                  )}
                  <hr />
                  <button 
                    className="submit-issue-button" 
                    onClick={handleSubmitIssue}
                    disabled={selectedIssue === "Other" && otherIssueText.trim() === ""}
                  >
                    Submit Issue
                  </button>
                  </div>
                  </div>
                  )}
                  </div>
                  );
                  }
                  export default PersonalizedExam;
