export const fetchUserExams = async (level, section) => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch("https://api.getmina.co/api/v1/panel/get-user-exams", {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ level, section })
      });
      const data = await response.json();
      return Array.isArray(data) ? data : [];
    } catch (error) {
      console.error("Error fetching exams:", error);
      throw error;
    }
  };
  
  export const initiateCheckoutSession = async () => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await fetch('https://api.getmina.co/api/v1/payment/create-checkout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
      });
      return response.json();
    } catch (error) {
      console.error('Failed to initiate checkout:', error);
      throw error;
    }
  };