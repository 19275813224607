import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/RegistrationSteps.css';
import '../css/Login.css';
import Logo from '../assets/images/logo.png';

import {
  Box,
 
} from '@mui/material';
function JLPTExperienceStep() {
  const [hasExperience, setHasExperience] = useState(null);
  const navigate = useNavigate();

  const handleExperienceClick = (experience) => {
    setHasExperience(experience);
    localStorage.setItem('jlptExperience', experience);
    navigate('/target-level');
  };

  return (
    <div>
        <Box className="logo-container-box" sx={{ position: 'relative', top: '0px',  }}>
        <img src={Logo} alt="Mina Logo" style={{ height: '50px' }} />
      </Box>
    <div className='check-body'>
    <div className="registration-step">
    
      <div className="step-indicator">1/2</div>
      <div className="first-title">Have you ever taken the JLPT before?</div>
      <p className="sub-title">Mina recommends customized lectures and study courses depending on your experience with the JLPT.</p>
      <div className='space'></div>
      <div className="button-group">
        <button 
          className={`experience-button ${hasExperience === true ? 'selected' : ''}`}
          onClick={() => handleExperienceClick(true)}
        >
          Yes
        </button>
        <button 
          className={`experience-button ${hasExperience === false ? 'selected' : ''}`}
          onClick={() => handleExperienceClick(false)}
        >
          No, I have never taken the JLPT before
        </button>
      </div>
    </div>
    </div>
    </div>
  );
}

export default JLPTExperienceStep;