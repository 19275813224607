import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './dashboardstyle.css';

function PersonalizedExamResultsPage() {
  const { examId } = useParams();
  const [examData, setExamData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExamResults = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        const response = await fetch(`https://api.getmina.co/api/v1/panel/personalized-exam/${examId}`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${jwt}` }
        });
        const data = await response.json();
        console.log(data);
        setExamData(data);
      } catch (error) {
        console.error(error);
        setError('Failed to fetch exam results.');
      }
      setLoading(false);
    };

    fetchExamResults();
  }, [examId]);

  if (loading) return <div className="loader-container">Loading...</div>;
  if (error) return <div className="error-container">{error}</div>;
  if (!examData) return <div className="error-container">No exam data available.</div>;

  return (
    <div className="container">
      <h1 className="page-title">Personalized Exam Results</h1>
      <h2>Exam ID: {examData.exam_id}</h2>
      <h3>Score: {examData.score.toFixed(2)}%</h3>
      <p>Completed on: {new Date(examData.created_at).toLocaleString()}</p>
      <div className="questions-container">
        {examData.questions.map((question, index) => (
          <div key={question.question_id} className={`question-item ${question.answer === question.choices.choices[0] ? 'correct' : 'incorrect'}`}>
            <h3>Question {index + 1}</h3>
            <p className="question-text">{question.question}</p>
            {question.script && <p className="question-script">{question.script}</p>}
            <p>Correct Answer: {question.answer}</p>
            <div className="choices">
              {question.choices.choices.map((choice, choiceIndex) => (
                <div 
                  key={choiceIndex} 
                  className={`choice ${choice === question.answer ? 'correct' : ''}`}
                >
                  {choice}
                </div>
              ))}
            </div>
            {question.hint && <p className="question-hint"><strong>Hint:</strong> {question.hint}</p>}
            {question.instruction && <p className="question-instruction"><strong>Instruction:</strong> {question.instruction}</p>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PersonalizedExamResultsPage;