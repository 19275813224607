export const extractCategories = (exams) => {
    const allCategories = new Set(exams.flatMap(exam => {
      if (Array.isArray(exam.categories)) {
        return exam.categories;
      }
      return [];
    }));
    return Array.from(allCategories);
  };
  
  export const formatText = (text) => {
    return text || 'N/A';
  };
  
  export const formatCategories = (categories) => {
    if (!Array.isArray(categories) || categories.length === 0) return 'N/A';
    if (categories.length === 1) return categories[0];
    if (categories.length === 2) return `${categories[0]} and ${categories[1]}`;
    return categories.join(', ');
  };