import React from 'react';
import Dropdown from '../../components/Dropdown';
import '../../css/Home.css';

const StudyBar = ({ selectedLevel, levels, onLevelChange }) => (
  <div className="selected-study-bar">
    <div className="selected-study-content">
      <div className="selected-study-text">
        <div className="selected-study-title">Selected study</div>
        <p className="selected-study-subtitle">
          Select and learn only the content you want.
        </p>
      </div>
      <div className="level-selector-container">
        <div className="selector-title">Level</div>
        <Dropdown
          options={levels}
          selected={selectedLevel}
          onChange={onLevelChange}
        />
      </div>
    </div>
  </div>
);

export default StudyBar;